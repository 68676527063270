import { ArrowSmRightIcon } from '@heroicons/react/outline';
import { StyledOrderStatus } from 'components/StyledOrderStatus';
import { Invoice } from 'features/sell';
import { getPaymentType } from 'features/transactions/utils';
import { capitalizeFirstLetter, numberFormatter } from 'utils/formatters';

type InvoiceCardProps = {
  invoice: Invoice;
  onSelectInvoice: (invoice: Invoice) => void;
};

export const InvoiceCard = ({ invoice, onSelectInvoice }: InvoiceCardProps) => {
  return (
    <article
      className="border border-gray-dark rounded-lg py-5 my-4 px-3"
      onClick={() => onSelectInvoice}
    >
      <div className="flex justify-between items-center">
        <p> {getPaymentType(invoice.service_provider)}</p>
        <span
          className={`text-xs ${
            invoice.transaction_type === 'buy'
              ? 'bg-light-blue text-secondary'
              : 'bg-light-green text-primary'
          } py-1.5 px-5  font-semibold rounded-full`}
        >
          {capitalizeFirstLetter(invoice.transaction_type)}
        </span>
      </div>
      <div className="flex items-center gap-3 text-sm">
        <p>{invoice.amount}</p>
        <ArrowSmRightIcon className="h-7 text-primary" />
        <p> {numberFormatter(parseFloat(invoice.amount_in_crypto), 8)} </p>
      </div>
      <div>
        <StyledOrderStatus orderStatus={invoice.status} />
      </div>
    </article>
  );
};
