import { ProgressBar } from 'components/ProgressBar';
import { Collapse, Accordion } from '@mantine/core';
import { useState } from 'react';
import { IBuyLimits, ISellLimits } from '../types/limits';
import { calculateLimitPercentage, calculateRemainingLimit } from '../utils';

interface BuyLimitsProps {
  limits: IBuyLimits;
}

export const BuyLimits = ({ limits }: BuyLimitsProps) => {
  const { bank_card, account_balance, cash } = limits;
  const limitsToRender = [
    {
      header: 'Bank Card',
      limit: bank_card,
    },
    {
      header: 'Account Balance',
      limit: account_balance,
    },
    {
      header: 'Cash',
      limit: cash,
    },
  ];
  return (
    <div className="dashboard-page">
      <header className="mb-5">
        <div>
          <h2 className="mb-2 text-secondary">Buy Limits </h2>
          <p>Read transactional limits and service limits</p>
        </div>
      </header>
      <Accordion>
        {limitsToRender.map((item) => (
          <Accordion.Item label={item.header} iconPosition="right">
            <div>
              <h4 className="text-gray-darker my-2"> Daily Limit</h4>
              <div className="flex justify-between">
                <p className="text-primary text-xs my-1">
                  $
                  {calculateRemainingLimit(
                    item.limit.daily_limit,
                    item.limit.daily_sum
                  )}{' '}
                  Remaining
                </p>
                <p className="text-gray-darker text-xs my-1">
                  ${item.limit.daily_limit} per day
                </p>
              </div>
              <ProgressBar
                progressPercentage={calculateLimitPercentage(
                  item.limit.daily_limit,
                  item.limit.daily_sum
                )}
              />
            </div>
            <div className="my-8">
              <h4 className="text-gray-darker my-2"> Weekly Limit</h4>
              <div className="flex justify-between">
                <p className="text-primary text-xs my-1">
                  $
                  {calculateRemainingLimit(
                    item.limit.weekly_limit,
                    item.limit.weekly_sum
                  )}{' '}
                  Remaining
                </p>
                <p className="text-gray-darker text-xs my-1">
                  ${item.limit.weekly_limit} per week
                </p>
              </div>
              <ProgressBar
                progressPercentage={calculateLimitPercentage(
                  item.limit.weekly_limit,
                  item.limit.weekly_sum
                )}
              />
            </div>
            <div>
              <h4 className="text-gray-darker my-2"> Monthly Limit</h4>
              <div className="flex justify-between">
                <p className="text-primary text-xs my-1">
                  {' '}
                  $
                  {calculateRemainingLimit(
                    item.limit.monthly_limit,
                    item.limit.monthly_sum
                  )}{' '}
                  Remaining
                </p>
                <p className="text-gray-darker text-xs my-1">
                  ${item.limit.monthly_limit} per month
                </p>
              </div>
              <ProgressBar
                progressPercentage={calculateLimitPercentage(
                  item.limit.weekly_limit,
                  item.limit.weekly_sum
                )}
              />
            </div>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};
