import { useFormik } from 'formik';
import * as Yup from 'yup';
import exclamation from 'assets/icons/exclamation.svg';
import arrow_right from 'assets/icons/arrow_right.svg';
import TextField from 'components/TextField';
import Button from 'components/Button';
import { AccountInfoDTO, useUpdateAccountInfo } from 'features/account';
import { UpdateAccountInfoResponse } from 'features/account/types/updateAccountInfoResponse';

type EmailRegistrationFormProps = {
  onComplete: (email: string) => void;
};

const EmailRegistrationForm = ({ onComplete }: EmailRegistrationFormProps) => {
  const onSuccess = (data: UpdateAccountInfoResponse) => {
    onComplete(values.email);
  };

  const apiQuery = useUpdateAccountInfo(onSuccess);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik<Pick<AccountInfoDTO, 'email'>>({
      initialValues: {
        email: '',
      },
      validationSchema: Yup.object({
        email: Yup.string().required().email(),
      }),
      onSubmit: (values) => {
        apiQuery.update(values);
      },
    });

  return (
    <div>
      <div className="mb-12 flex flex-col space-y-8">
        {[
          'For Level 2 or to sell cryptocurrency, please verify your email address',
        ].map((note) => (
          <div
            key={note}
            className="content-fit flex items-center space-x-4 bg-light-green p-4 pr-12 text-base"
          >
            <img className="h-6 w-auto" src={exclamation} alt="" />
            <span>{note}</span>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <header>
          <h2 className="text-3xl font-bold">Email Address</h2>
          <p>Enter your email address below to add it to your account.</p>
        </header>
        <TextField
          className="my-5"
          label="Email Address"
          placeholder="Email"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email && touched.email && errors.email}
        />
        <div className="flex flex-col justify-evenly space-y-4 md:flex-row md:space-x-4 md:space-y-0">
          <Button
            type="submit"
            fullWidth={true}
            loading={apiQuery.isLoading}
            iconPosition="right"
            icon={<img src={arrow_right} className="h-4" alt="right arrow" />}
          >
            Send Verification
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EmailRegistrationForm;
