import { useFormik } from 'formik';
import * as Yup from 'yup';
import WalletIcon from 'assets/icons/wallet.svg';
import TextField from 'components/TextField';
import {
  SupportedCurrencies,
  Wallet,
  WalletFields,
  WalletResponse,
} from '../types/wallet';
import { Select } from '@mantine/core';
import { getCryptoCurrencies, getWalletIcon } from '../util/walletIcons';
import { CryptoSelectItem } from './CryptoSelectItem';
import { useUpdateWallet } from '../api/updateWallet';
import Button from 'components/Button';
import { verifyCrypto } from '../api/verifyCrpyto';
import { capitalizeFirstLetter } from 'utils/formatters';

interface ModifyWalletModalProps {
  type: 'save' | 'edit';
  onSuccess: (data: WalletResponse) => void;
  address?: string;
  crypto?: string /** The Cryptocurrency for the wallet */;
  wallet?: Wallet;
}
export const ModifyWalletModal = ({
  type,
  onSuccess,
  wallet,
  crypto,
  address,
}: ModifyWalletModalProps) => {
  const { isLoading, update: updateWallet } = useUpdateWallet((resp) => {
    onSuccess(resp);
  });

  const { handleChange, handleSubmit, setFieldValue, errors, touched } =
    useFormik<WalletFields>({
      initialValues: {
        name: wallet ? wallet.name : '',
        crypto: wallet
          ? (wallet.crypto as keyof typeof SupportedCurrencies)
          : crypto
          ? (crypto as keyof typeof SupportedCurrencies)
          : '',
        address: wallet ? wallet.address : address ? address : '',
        is_default: false,
      },
      validationSchema: Yup.object({
        name: Yup.string().required('Please enter the name of your wallet'),
        crypto: Yup.string().required('Please select a cryptocurrency'),
        address: Yup.string()
          // Validate Wallet through endpoint
          .test(
            'isWalletValid',
            'The Wallet Address entered is invalid',
            async (value, testContext) => {
              const credentials = {
                address: value!,
                crypto: testContext.parent.crypto,
              };
              const result = await verifyCrypto(credentials);
              return result.isWalletValid;
            }
          )
          .required('Please enter the address of your crypto wallet'),
      }),
      onSubmit: async (values) => {
        console.log('Submitting');
        console.log(values);
        updateWallet({ ...values });
      },
    });

  const handleSelectChange = (e: any) => {
    console.log(e);
    setFieldValue('crypto', e); // set formik value
  };
  return (
    <form>
      {console.log('Crypto is', crypto)}
      <div className="flex flex-col items-center mb-5">
        <div className="mb-3 inline-block rounded-full bg-light-green p-5">
          <img src={WalletIcon} className="h-10" alt="wallet icon" />
        </div>
        <h2 className="font-lg"> {capitalizeFirstLetter(type)} Wallet</h2>

        <p className="text-sm">
          {' '}
          Please enter the following details to{' '}
          {type === 'save' ? 'Save' : 'Modify'} this wallet to your account
        </p>
      </div>
      <TextField
        name="name"
        error={errors.name && touched.name && errors.name}
        onChange={handleChange}
        placeholder={'Wallet Name'}
      />
      {/* Hide Form fields if wallet address is provided */}
      {!address && (
        <div>
          <Select
            styles={{
              input: { fontSize: '18px', height: '60px' },
            }}
            error={errors.crypto && touched.crypto && errors.crypto}
            itemComponent={CryptoSelectItem}
            className="my-3"
            variant="filled"
            color="gray-dark"
            size="lg"
            name="crypto"
            searchable
            disabled={wallet ? true : false}
            onChange={(e) => {
              handleSelectChange(e);
            }}
            value={wallet ? wallet.address : crypto}
            placeholder="Select Cryptocurrency"
            data={getCryptoCurrencies()}
          />

          <TextField
            name="address"
            onChange={handleChange}
            placeholder="Wallet Address"
            disabled={true}
            value={wallet ? wallet.address : address}
            error={errors.address && touched.address && errors.address}
          />
        </div>
      )}

      <Button
        onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        size="sm"
        type="submit"
        className="my-3"
        loading={isLoading}
      >
        Save {type === 'save' ? 'Wallet' : 'Changes'}
      </Button>
    </form>
  );
};
