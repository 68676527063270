import { Checkbox } from '@mantine/core';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Button from 'components/Button';
import TextField from 'components/TextField';
import storage from 'utils/storage';
import { LoginCredentialsDTO, useLogin } from '../api/login';
import { LoginApiResponse } from '../types/login';
import { useAuth } from '../providers';
import { useModals } from '@mantine/modals';
import ForgotPasswordModal from './ForgotPasswordModal';
import { ChangeEvent, useEffect, useState } from 'react';
import PasswordField from 'components/PasswordField';
import { trackLogin } from 'features/sift/api/siftEvents';
import { axios } from 'lib/axios';
import { BROWSER_OBJECT } from 'features/sift/util/constants';
import { useVerify } from '../providers/verification';

type LoginFormProps = {
  onSuccess: () => void;
};

const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const { setUser } = useAuth();
  const [rememberMe, setRememberMe] = useState(storage.getRememberMe());

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const { location } = useVerify();

  const handleSuccess = (data: LoginApiResponse) => {
    setUser(data.user);
    // Temporarily store token before 2FA validation
    storage.setTempToken(data.unbank.token);
    storage.setToken(data.unbank.token);

    // Keep track of login event
    if (data) {
      const siftCredentials = {
        $ip: location?.IPv4!,
        $type: '$login',
        $api_key: process.env.REACT_APP_SIFT_BEACON_KEY || '',
        $username: data.user.email,
        $login_status: '$success',
        $user_email: data.user.email,
        $session_id: data.unbank.token,
        $user_id: data.user.id.toString(),
        $verification_phone_number: data.user.phone_number,
        $brand_name: 'unbank',
        $site_domain: 'app.unbankworld.com',
        $site_country: 'JM',
        $browser: BROWSER_OBJECT,
      };
      trackLogin(siftCredentials);
    }

    onSuccess();
  };

  const apiQuery = useLogin(handleSuccess);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik<LoginCredentialsDTO>({
      initialValues: {
        phone_number: storage.getPhone(),
        password: '',
      },
      validationSchema: Yup.object({
        phone_number: Yup.string()
          .required('Phone Number is a required field')
          .min(10, 'Phone Number must be at least 10 characters'),
        password: Yup.string().required().min(8),
      }),
      onSubmit: (values) => {
        apiQuery.update(values);
      },
    });

  /** Custom onChange to prevent user from adding digits into phone number field */
  const onChange = (event: ChangeEvent<any>) => {
    const value = (event.target as HTMLInputElement).value;
    const isValid = /^\d+$/.test(value);

    if (isValid) {
      (event.target as HTMLInputElement).value = value;
    } else {
      (event.target as HTMLInputElement).value = value.replace(/[^\d+]/g, '');
    }

    handleChange(event);
  };

  useEffect(() => {
    storage.setRememberMe(rememberMe);
    if (rememberMe) {
      storage.setPhone(values.phone_number);
    } else {
      storage.clearPhone();
    }
  }, [rememberMe, values.phone_number]);

  const modals = useModals();

  const openForgotPasswordModal = () => {
    const modalId = modals.openModal({
      closeOnClickOutside: false,
      children: (
        <div className="max-w-4xl">
          <h2 className="mb-4 text-center text-lg font-semibold">
            Password Recovery
          </h2>
          <ForgotPasswordModal />
        </div>
      ),
    });
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-xl">
      <header>
        {console.log(location?.IPv4)}
        <h1 className="text-3xl font-bold">Sign In</h1>
        <p>Enter your login details below</p>
      </header>
      <div className="my-5 space-y-5">
        <TextField
          label="Phone Number"
          placeholder="Phone"
          name="phone_number"
          type="tel"
          value={values.phone_number}
          onChange={onChange}
          onBlur={handleBlur}
          error={
            errors.phone_number && touched.phone_number && errors.phone_number
          }
        />
        <PasswordField
          label="Password"
          placeholder="Password"
          name="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password && touched.password && errors.password}
        />
        <div className="flex items-center justify-between">
          <Checkbox
            label="Remember Me"
            classNames={{ input: 'border-primary' }}
            checked={rememberMe}
            onChange={handleCheckbox}
          />
          <button
            onClick={openForgotPasswordModal}
            type="button"
            className="cursor-pointer text-gray-dark transition-colors ease-in hover:text-primary hover:underline"
          >
            Forgot password?
          </button>
        </div>
        <div className="text-center">
          <Button
            type="submit"
            className="mb-8 w-full lg:w-2/5"
            loading={apiQuery.isLoading}
          >
            Sign In
          </Button>

          <div>
            Don't have an account?{' '}
            <Link to="/auth/register" className="text-primary hover:underline">
              Register
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
