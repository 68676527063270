import { Stepper } from '@mantine/core';
import { useModals } from '@mantine/modals';
import Button from 'components/Button';
import { StyledStepper } from 'components/StyledStepper';
import { ForgotPasswordDTO } from 'features/auth/api/forgotPassword';
import { useState } from 'react';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { ResetPasswordForm } from './ResetPasswordForm';

const ForgotPasswordModal = () => {
  const [active, setActive] = useState(0);
  const [chosenMedium, setChosenMedium] = useState<ForgotPasswordDTO | null>(
    null
  );
  const modals = useModals();

  const nextStep = () =>
    setActive((current) => (current < 2 ? current + 1 : current));

  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  const handleForgotPasswordSubmission = (value: ForgotPasswordDTO) => {
    setChosenMedium(value);
    nextStep();
  };

  // type ContactDetail = { type: 'email' | 'phone'; value: string } | null;

  // const [contactDetail, setContactDetail] = useState<ContactDetail>(null);

  return (
    <StyledStepper
      classNames={{
        stepBody: 'text-center',
      }}
      active={active}
    >
      <Stepper.Step label="Request code">
        <ForgotPasswordForm onFormSubmit={handleForgotPasswordSubmission} />
      </Stepper.Step>
      <Stepper.Step label="Reset Password">
        <ResetPasswordForm
          onFormSubmit={nextStep}
          forgotPasswordRequestData={chosenMedium!}
        />
      </Stepper.Step>
      <Stepper.Step label="Return to Sign In">
        <p className="mb-6">Your password has successfully been changed.</p>
        <Button type="button" fullWidth onClick={() => modals.closeAll()}>
          Go To Login
        </Button>
      </Stepper.Step>
    </StyledStepper>
  );
};

export default ForgotPasswordModal;
