import { useMediaQuery } from '@mantine/hooks';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { theme } from 'lib/mantineTheme';
import { modalProps } from 'lib/mantineModals';
import { AuthProvider } from 'features/auth';
import ErrorBoundary from 'components/ErrorBoundary';
import { SystemInfoProvider } from 'common';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { VerificationProvider } from 'features/auth/providers/verification';
import { LimitsProvider } from 'features/transactions/providers/limits';

export const AppProviders: React.FC = ({ children }) => {
  const matches = useMediaQuery('(max-width: 760px)');

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <MantineProvider theme={theme}>
          <AuthProvider>
            <VerificationProvider>
              <LimitsProvider>
                <SystemInfoProvider>
                  <ModalsProvider
                    modalProps={{
                      ...modalProps,
                      size: matches ? 'full' : 'xl',
                    }}
                  >
                    {children}
                    <ToastContainer newestOnTop />
                  </ModalsProvider>
                </SystemInfoProvider>
              </LimitsProvider>
            </VerificationProvider>
          </AuthProvider>
        </MantineProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
