import { NavItem } from './../components/NavItem';
import {
  PencilIcon,
  PhoneIcon,
  AtSymbolIcon,
  UserCircleIcon,
  CreditCardIcon,
  IdentificationIcon,
  ShieldCheckIcon,
  BellIcon,
} from '@heroicons/react/solid';

import { Wallet } from '@mui/icons-material';
import { Outlet } from 'react-router-dom';
import { useAuth } from 'features/auth';
import { detectViewport } from 'utils/layout';
import { useEffect, useState } from 'react';
import { Accordion, Group } from '@mantine/core';

interface INavItem {
  label: string;
  link: string;
  image: JSX.Element;
}
const imageStyles = 'h-5 text-primary';

const navItems: INavItem[] = [
  {
    label: 'General',
    link: '/dashboard/account/general',
    image: <UserCircleIcon className={imageStyles} />,
  },
  {
    label: 'Payment Methods',
    link: '/dashboard/account/payment-methods',
    image: <CreditCardIcon className={imageStyles} />,
  },
  {
    label: 'Wallet',
    link: '/dashboard/account/wallet',
    image: <Wallet className={imageStyles} />,
  },
  {
    label: 'Identity Verification',
    link: '/dashboard/account/identity-verification',
    image: <IdentificationIcon className={imageStyles} />,
  },
  {
    label: 'Security',
    link: '/dashboard/account/security',
    image: <ShieldCheckIcon className={imageStyles} />,
  },
  {
    label: 'Cashout Methods',
    link: '/dashboard/account/cashout-methods',
    image: <ShieldCheckIcon className={imageStyles} />,
  },
  {
    label: 'Notification Preferences',
    link: '/dashboard/account/notification-preferences',
    image: <BellIcon className={imageStyles} />,
  },
];

interface AccordionLabelProps {
  label: string;
  image: JSX.Element;
}

function AccordionLabel({ label, image }: AccordionLabelProps) {
  return (
    <div className="flex gap-5">
      {image}
      <div>
        <p className="text-primary">{label}</p>
      </div>
    </div>
  );
}

const defaultItem = {
  label: 'General',
  link: '/dashboard/account/general',
  image: <UserCircleIcon className={imageStyles} />,
};
export const AccountPage = () => {
  const [viewport, setViewport] = useState(detectViewport());
  const [activeLink, setActiveLink] = useState<INavItem>(defaultItem);

  // Create a function to update the viewport/device
  const updateViewport = () => {
    setViewport(detectViewport());
  };

  // Use the useEffect hook to detect any changes to the user's viewport/device
  useEffect(() => {
    window.addEventListener('resize', updateViewport);
    return () => window.removeEventListener('resize', updateViewport);
  });

  const onNavigate = (navItem: INavItem) => {
    setActiveLink(navItem);
  };
  const { user } = useAuth();
  return (
    <div>
      {viewport === 'mobile' ? (
        <div className="dashboard-page">
          {/* Navigation */}
          <Accordion iconPosition="right" className="">
            <Accordion.Item
              label={
                <AccordionLabel
                  label={activeLink.label}
                  image={activeLink.image}
                />
              }
            >
              <ul>
                {console.log(activeLink)}
                {navItems.map((item) => (
                  <NavItem
                    onNavigate={() => onNavigate(item)}
                    key={item.link}
                    {...item}
                  />
                ))}
              </ul>
            </Accordion.Item>
          </Accordion>
          <div className="mt-5">
            <Outlet />
          </div>
        </div>
      ) : (
        <div className="dashboard-page">
          {/* Header  */}
          <header className="relative">
            <div className="flex flex-col items-center gap-6 text-center sm:flex-row sm:text-left">
              <img
                src={user?.profile_photo_url}
                alt=""
                className="h-20 rounded-full md:h-36"
              />
              <div className="flex flex-col items-center gap-1 sm:items-start">
                <h1 className="text-xl font-semibold">{user?.name}</h1>
                {user?.email && (
                  <p className="flex items-center gap-2 text-sm">
                    {' '}
                    <AtSymbolIcon className="h-4" />
                    {user?.email}
                  </p>
                )}
                <p className="flex items-center gap-2 text-sm">
                  <PhoneIcon className="h-4" />
                  {user?.phone_number}
                </p>
              </div>
            </div>
          </header>

          <nav className="my-10 -mx-8 w-screen overflow-x-auto bg-gray-light sm:mx-0 sm:w-full">
            <ul className="flex gap-6 whitespace-nowrap py-6 px-6 md:gap-12 md:px-12">
              {navItems.map((item) => (
                <NavItem
                  onNavigate={() => onNavigate(item)}
                  key={item.link}
                  {...item}
                />
              ))}
            </ul>
          </nav>

          {/* body  */}
          <div className="">
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
};
