import Button from 'components/Button';
import { Invoice, PaymentPage } from 'features/sell';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type InvoiceModalProps = {
  invoice: Invoice;
};

export const InvoiceModal = ({ invoice }: InvoiceModalProps) => {
  const [hasPaid, setHasPaid] = useState(false);
  const navigate = useNavigate();
  const handlePaymentReceived = () => {
    setHasPaid(true);
  };

  const reloadPage = () => window.location.reload();

  const handleRestartProcess = () => {
    navigate('/dashboard/sell');
  };

  return (
    <div>
      {hasPaid ? (
        <div className="flex flex-col items-center gap-4">
          <h3 className="text-lg">
            You can continue the process by reloading the page and clicking on
            the transaction
          </h3>
          <Button onClick={reloadPage}>Reload</Button>
        </div>
      ) : (
        <PaymentPage
          sellInvoice={invoice}
          onRestartProcess={handleRestartProcess}
          onPaymentReceived={handlePaymentReceived}
        />
      )}
    </div>
  );
};
