import { AdjustmentsIcon, BellIcon } from '@heroicons/react/outline';
import { ScrollArea } from '@mantine/core';
import { UnbankNotification, NotificationType } from '../types/notifications';
import TimeAgo from 'react-timeago';
import { useNavigate } from 'react-router-dom';
import { NotificationIcon } from './NotificationIcon';

interface NotificationsProps {
  notifications?: UnbankNotification[];
}

export const Notifications = ({ notifications }: NotificationsProps) => {
  const navigate = useNavigate();

  return (
    <div className="">
      <header className="flex items-center justify-between gap-10 py-2 border-b border-gray-dark">
        <h3 className="text-secondary font-semibold text-lg">Notifications</h3>
        <div className="hover:cursor-pointer flex gap-2 items-center  text-primary">
          <p
            onClick={() => {
              navigate('/dashboard/account/notification-preferences');
            }}
            className="text-sm"
          >
            Manage Preferences
          </p>
          <AdjustmentsIcon className="h-5" />
        </div>
      </header>

      {/* Notifications */}
      <section>
        {notifications?.length ? (
          <ScrollArea style={{ height: 250 }}>
            {' '}
            {notifications.map((notification, index) => (
              <div>
                <article
                  className={`py-4 w-full grid grid-cols-8  ${
                    index !== notifications.length - 1
                      ? ' border-b border-gray-dark'
                      : ''
                  }`}
                  key={index}
                >
                  <div className="bg-primary h-9 w-9 flex justify-center rounded">
                    <NotificationIcon notificationType={notification.type} />
                  </div>
                  <div className="col-span-7">
                    <h4 className="text-sm font-semibold ">
                      {notification.data.title}
                    </h4>
                    <p className="text-xs w-4/5">{notification.data.message}</p>
                    <p className="text-xs font-semibold text-gray-secondaryDark">
                      <TimeAgo date={new Date(notification.created_at)} />
                    </p>
                  </div>
                </article>
              </div>
            ))}
          </ScrollArea>
        ) : (
          <div className="flex flex-col items-center justify-center h-64">
            <BellIcon className="h-12 w-12 text-gray-dark" />
            <h4 className="font-semibold text-gray-dark">
              No new notifications
            </h4>
            <p className="text-sm text-gray-darker">
              {' '}
              Hooray! You're all caught up!
            </p>
          </div>
        )}
      </section>
    </div>
  );
};
