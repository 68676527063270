import checkMark from 'assets/icons/check-mark.svg';
import exclamation from 'assets/icons/exclamation.svg';
import Button from 'components/Button';
import { ViewWalletsModal } from 'features/wallet/components/ViewSavedWallets';
import TextField from 'components/TextField';
import { useEffect, useState } from 'react';
import { MinerFee } from 'common/types';
import { useFormik, useField } from 'formik';
import * as Yup from 'yup';
import WalletIcon from 'assets/icons/WalletIcon.svg';
import { Wallet } from 'features/wallet/types/wallet';
import { getWallets } from 'features/wallet/api/getWalletInfo';
import { Loader, Modal } from '@mantine/core';
import { ArrowNarrowLeftIcon } from '@heroicons/react/solid';
import { PromptSaveWallet } from 'features/wallet/components/PromptSaveWallet';
import { getCryptoCurrencyTitle } from 'utils/formatters';
import ResponsiveModal from 'components/Modal';

type BuyTransactionFormProps = {
  onSelect: (speed: { label: string; amount: number }, address: string) => void;
  minerFees: MinerFee;
  crypto: string;
  paymentProcess: string;
  goBack: () => void;
};

type MiningFee = {
  speed: string;
  fee: number;
  minSpeed: number;
  maxSpeed: number;
  timeMesasurement: string;
};

export const BuyTransactionForm = ({
  onSelect,
  minerFees,
  crypto,
  paymentProcess,
  goBack,
}: BuyTransactionFormProps) => {
  const [speeds, setSpeeds] = useState<MiningFee[]>([]);
  const [selectedSpeed, setSelectedSpeed] = useState<MiningFee>(speeds[0]);

  const [wallets, setWallets] = useState<Wallet[]>();

  const [noWallets, setNoWallets] = useState(0);
  const [walletPromptOpened, setWalletPromptOpened] = useState(false);
  const [savedWalletsOpened, setSavedWalletsOpened] = useState(false);
  const [continueBuyProcess, setContinueBuyProcess] = useState(false);
  const [walletAddr, setWalletAddr] = useState('');

  /** Pull Mining fees */
  useEffect(() => {
    const slow: MiningFee = {
      speed: 'slow',
      fee: minerFees.low_fee_in_usd,
      minSpeed: 4,
      maxSpeed: 24,
      timeMesasurement: 'hrs',
    };

    const fast: MiningFee = {
      speed: 'fast',
      fee: minerFees.high_fee_in_usd,
      minSpeed: 4,
      maxSpeed: 60,
      timeMesasurement: 'mins',
    };

    setSpeeds([fast, slow]);
    setSelectedSpeed(fast);
  }, []);

  /** Pull list of wallets when component is rendered */
  useEffect(() => {
    const fetchWallets = async () => {
      const walletData = await getWallets();
      setWallets(walletData.data);
      setNoWallets(walletData.data.length);
    };

    fetchWallets().catch(console.error);
  }, []);

  const isWalletSaved = (walletAddr: string) => {
    for (let wallet of wallets!) {
      if (wallet.address === walletAddr) {
        return true;
      }
    }

    return false;
  };

  // Set Wallet field after its selected in modal
  const handleWalletSelected = (wallet: Wallet) => {
    formik.setFieldValue('address', wallet.address);
    setSavedWalletsOpened(false);
  };

  const { errors, touched, handleSubmit, ...formik } = useFormik<{
    address: string;
  }>({
    initialValues: {
      address: '',
    },
    validationSchema: Yup.object({
      address: Yup.string().required(),
    }),
    onSubmit: (values) => {
      // Check if user has current wallet saved
      const walletSaved = isWalletSaved(values.address);

      // Only prompt to save wallet if user has no wallets
      // or if the current wallet is not saved
      // If the user has 6 wallets they will not be prompted to save their wallet
      if ((noWallets === 0 || !walletSaved) && wallets && wallets.length < 6) {
        setWalletAddr(values.address);
        setWalletPromptOpened(true);
      } else {
        // Proceed with buy flow
        onSelect(
          { label: selectedSpeed.speed, amount: selectedSpeed.fee },
          values.address
        );
      }
    },
  });

  return (
    <div>
      <ArrowNarrowLeftIcon
        className="h-6 my-3 text-tw-gray-600 cursor-pointer"
        onClick={goBack}
      />
      <div className="mb-5 text-left md:text-center">
        <h3 className=" text-2xl font-semibold">Transaction Process</h3>
        <p className="text-tw-gray-600">
          Choose how you would like your transactions to be processed
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        <section>
          <div className="flex justify-between my-auto items-center align-center mb-3">
            <h4 className=" text-lg font-semibold">
              {getCryptoCurrencyTitle(crypto)} Address
            </h4>
            <button
              type="button"
              className=" text-white py-3 rounded-full bg-primary border flex items-center justify-center px-6 "
              onClick={() => {
                setSavedWalletsOpened(true);
              }}
            >
              <img src={WalletIcon} alt="wallet" className="mr-4 h-5" />
              <p>Use Saved Wallet</p>
            </button>
          </div>
          <TextField
            placeholder="Address"
            {...formik.getFieldProps('address')}
            error={errors.address && touched.address && errors.address}
          />
          <p className="mt-2 flex items-start gap-2 text-sm">
            <span className="inline-grid shrink-0 place-items-center rounded-full bg-light-green p-1">
              <img className="h-4" src={exclamation} alt="" />
            </span>
            Make sure you control this address, your address can't be changed
            after payment
          </p>
        </section>

        <section>
          <h4 className="mb-4 text-lg font-semibold">Transaction Speed</h4>

          <div className="space-y-6">
            {speeds.map((speed) => (
              <div
                key={speed.speed}
                onClick={() => setSelectedSpeed(speed)}
                className={`relative flex cursor-pointer gap-4 rounded-md border-2 border-gray-medium bg-gray-light p-4`}
              >
                <span
                  className={`inline-grid h-5 w-5 shrink-0 place-items-center rounded-full border ${
                    selectedSpeed === speed
                      ? 'border-primary bg-primary'
                      : 'border-gray-dark'
                  }`}
                >
                  <img src={checkMark} alt="" className="h-2" />
                </span>
                <div>
                  <div className="relative -top-1">
                    <span className="font-bold capitalize">{speed.speed}</span>{' '}
                    - ${speed.fee}
                  </div>
                  <div>
                    <span className="font-bold">Usually confirms </span> -{' '}
                    {speed.minSpeed} to {speed.maxSpeed}{' '}
                    {speed.timeMesasurement}
                  </div>
                </div>
              </div>
            ))}
            <div className="flex gap-4 rounded-md border-gray-medium border-2 p-4 py-6">
              <img className="h-6 shrink-0" src={exclamation} alt="" />
              <div>
                Estimated miner fees may fluctuate until payment is complete.
                Unbank is not responsible for cryptocurrency network delays.
              </div>
            </div>
          </div>
        </section>
        <div className="text-center">
          <Button type="submit" disabled={!selectedSpeed}>
            Proceed
          </Button>
        </div>
        <ResponsiveModal
          opened={walletPromptOpened}
          size="lg"
          onClose={() => {
            setWalletPromptOpened(false);
          }}
        >
          <PromptSaveWallet
            crypto={crypto}
            onSuccess={() => {
              onSelect(
                { label: selectedSpeed.speed, amount: selectedSpeed.fee },
                formik.values.address
              );
            }}
            address={walletAddr}
            close={() => {
              onSelect(
                { label: selectedSpeed.speed, amount: selectedSpeed.fee },
                formik.values.address
              );
              setWalletPromptOpened(false);
            }}
          />
        </ResponsiveModal>
        <ResponsiveModal
          size="75%"
          onClose={() => {
            setSavedWalletsOpened(false);
          }}
          opened={savedWalletsOpened}
        >
          {wallets ? (
            <ViewWalletsModal
              crypto={crypto}
              wallets={wallets!}
              onSelect={handleWalletSelected}
            />
          ) : (
            <Loader />
          )}
        </ResponsiveModal>
      </form>
    </div>
  );
};
