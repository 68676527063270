import { Modal, Popover } from '@mantine/core';
import { CashoutMethod, CashoutMethods } from 'common/types/user';
import { ModifyMethodForm } from '../ModifyMethodForm';
import { useState } from 'react';
import { capitalizeFirstLetter } from 'utils/formatters';
import { DeleteCashoutMethod } from '../DeleteCashoutMethod';
import './CashoutItem.css';
import NCRLogo from 'assets/icons/NCRLogo.png';
import PaypalLogo from 'assets/icons/PayPalLogo.png';
import VenmoLogo from 'assets/icons/VenmoLogo.png';
import AddPaypalLogo from 'assets/icons/AddPaypal.png';
import AddVenmoLogo from 'assets/icons/AddVenmo.png';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import ResponsiveModal from 'components/Modal';

type CashoutItemProps = {
  cashoutItem: [
    string,
    (
      | {
          email: string;
        }
      | {
          phone_number: string;
        }
      | { phone_number: string }
      | null
    )
  ];
};

type CashoutMethodOption = {
  label: string;
  handler: () => void;
};

export const CashoutItem = ({ cashoutItem, ...props }: CashoutItemProps) => {
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [addModalOpened, setAddModalOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const cashoutMethod = cashoutItem[0];
  const cashoutMethodData = cashoutItem[1];

  const contactField = cashoutMethod === 'paypal' ? 'email' : 'phone_number';

  const onEdit = () => {
    setEditModalOpened(true);
  };

  const onDelete = () => {
    setDeleteModalOpened(true);
  };
  const options: CashoutMethodOption[] = [
    {
      label: 'Delete',
      handler: onDelete,
    },
    {
      label: 'Edit',
      handler: onEdit,
    },
  ];

  const logos = {
    ncrpay360: NCRLogo,
    paypal: PaypalLogo,
    venmo: VenmoLogo,
  };

  interface AddLogos {
    venmo: string;
    paypal: string;
    ncrpay360: string;
  }
  const addLogos: AddLogos = {
    venmo: AddVenmoLogo,
    paypal: AddPaypalLogo,
    ncrpay360: '',
  };

  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const modifyModal = (type: string) => (
    <div>
      <h2 className="text-left">
        {type} Cashout Method - {capitalizeFirstLetter(cashoutMethod)}
      </h2>
      <p className="text-sm text-left">
        {type} the details for your your {cashoutMethod} account for cashout by
        providing your contact information linked to {cashoutMethod}
      </p>
    </div>
  );

  const deleteMethodTitle = (
    <div>
      <h2> Delete Cashout Method - {capitalizeFirstLetter(cashoutMethod)}</h2>
      <p className="text-sm">
        {' '}
        <p>
          {' '}
          Are you sure you want to this cashout method? If you would like to
          change your {contactField} associated with {cashoutMethod}, you can
          click the 'Edit' button
        </p>
      </p>
    </div>
  );
  return (
    <div className="h-full rounded-md p-8 text-gray-dark shadow-custom w-full">
      {/* Display if user has cashout method */}
      {cashoutMethodData ? (
        <article className="flex flex-col">
          {/* Show Popover if method isn't NCR */}
          {cashoutMethod !== 'ncrpay360' && (
            <Popover
              radius="md"
              spacing="xs"
              opened={showOptions}
              onClose={toggleShowOptions}
              position="right"
              target={
                <DotsVerticalIcon
                  onClick={toggleShowOptions}
                  className="absolute -right-2 h-5 cursor-pointer"
                />
              }
            >
              <div>
                <ul onClick={toggleShowOptions}>
                  {options.map((option) => (
                    <li
                      key={option.label}
                      className="-mx-2 rounded-md py-1 pl-2 pr-6 text-sm text-tw-gray-800 hover:bg-light-green hover:text-primary"
                    >
                      <button onClick={option.handler}>{option.label}</button>
                    </li>
                  ))}
                </ul>
              </div>
            </Popover>
          )}

          {/* Display Cashout Method Logo */}

          <div>
            <img
              src={logos[cashoutMethod as CashoutMethod]}
              alt={`${cashoutMethod} Logo`}
              className="h-8"
              id={`${cashoutMethodData ? '' : ''}`}
            />
          </div>

          {/* Cashout Method Contact Details */}
          <p className="text-left mt-6">
            {' '}
            {Object.entries(cashoutMethodData)[0][1]}
          </p>
        </article>
      ) : (
        <div className="flex justify-center h-full">
          {/* Display Add Cashout Method */}
          <button
            className="my-auto text-primary flex items-center gap-3 text-sm"
            onClick={() => setAddModalOpened(true)}
          >
            <img
              className="h-8"
              src={`${addLogos[cashoutMethod as CashoutMethod]}`}
            />
            Add your {capitalizeFirstLetter(cashoutMethod)} account
          </button>
        </div>
      )}
      <Modal
        opened={editModalOpened}
        title={modifyModal('Edit')}
        onClose={() => {
          setEditModalOpened(false);
        }}
        size="50%"
      >
        <ModifyMethodForm action={'Edit'} cashoutMethod={cashoutMethod} />
      </Modal>
      <ResponsiveModal
        opened={addModalOpened}
        title={modifyModal('Add')}
        onClose={() => {
          setAddModalOpened(false);
        }}
        size="50%"
      >
        <ModifyMethodForm action={'Add'} cashoutMethod={cashoutMethod} />
      </ResponsiveModal>
      <Modal
        opened={deleteModalOpened}
        title={deleteMethodTitle}
        onClose={() => {
          setDeleteModalOpened(false);
        }}
        size="50%"
      >
        {' '}
        <DeleteCashoutMethod cashoutMethod={cashoutMethod} />
      </Modal>
    </div>
  );
};
