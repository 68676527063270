import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { General } from '../components/General';
import { IdentityVerification } from '../components/IdentityVerification';
import { PaymentMethods } from 'features/payment-methods';
import { AccountPage } from './AccountPage';
import { TwoFactorAuth } from '../components/TwoFactorAuth';
import { WalletSettings } from 'features/wallet/components/WalletSettings';
import ComingSoon from 'components/ComingSoon';
import { CashoutMethods } from '../components/CashoutMethods';
import { NotificationPreferences } from '../components/NotificationPreferences';

export const AccountRoutes = () => (
  <Routes>
    <Route path="" element={<AccountPage />}>
      <Route path="general" element={<General />} />
      <Route path="payment-methods" element={<PaymentMethods />} />
      <Route path="wallet" element={<WalletSettings />} />
      <Route path="identity-verification" element={<IdentityVerification />} />
      <Route path="security" element={<TwoFactorAuth />} />
      <Route path="cashout-methods" element={<CashoutMethods />} />
      <Route
        path="notification-preferences"
        element={<NotificationPreferences />}
      />
    </Route>
  </Routes>
);
