import { Invoice } from '../../types/invoice';
import { SegmentedControl } from '@mantine/core';
import { useEffect, useState } from 'react';
import { ScanSegment } from './ScanSegment';
import { useCountdown } from '../../hooks/useCountDown';
import { CopySegment } from './CopySegment';
import { InvoiceExpired } from './InvoiceExpired';
import { useGetInvoice } from 'features/sell/api/getInvoice';
import { useClipboard } from '@mantine/hooks';
import { Tooltip } from '@mantine/core';
import { DocumentDuplicateIcon } from '@heroicons/react/outline';

import { ExclamationCircleIcon } from '@heroicons/react/solid';
import Button from 'components/Button';

const CHECK_INVOICE_INTERVAL_IN_MS = 1000 * 10;

type PaymentPageProps = {
  sellInvoice: Invoice;
  onPaymentReceived: () => void;
  onRestartProcess: () => void;
};

export const PaymentPage = ({
  sellInvoice,
  onPaymentReceived,
  onRestartProcess,
}: PaymentPageProps) => {
  const [selectedSegment, setSelectedSegment] = useState('scan');
  const [underpaid, setUnderpaid] = useState(false);
  const countdown = useCountdown(new Date(sellInvoice.expiry_date).getTime());
  const getInvoiceQuery = useGetInvoice((data) => {
    if (data.status === 'Settled') {
      onPaymentReceived();
    }

    if (
      data.amount_paid_in_usd > 0 &&
      data.amount_due_in_usd !== data.amount_paid_in_usd
    ) {
      setUnderpaid(true);
    }
  });

  useEffect(() => {
    const interval = setInterval(
      () => getInvoiceQuery.update(sellInvoice.btc_invoice_id),
      CHECK_INVOICE_INTERVAL_IN_MS
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  const payment_data = sellInvoice.payment_data[0];
  const { copy } = useClipboard();

  return (
    <>
      {underpaid && (
        <div className="flex gap-8 bg-red-400 py-4 px-5 items-center text-white rounded-t">
          <ExclamationCircleIcon className="h-8 text-white" />
          <p className="w-1/2 text-white">
            The Invoice hasn't been paid in full. Please send another
            transaction to cover the amount due.{' '}
          </p>
        </div>
      )}

      <section className="mt-8 grid grid-cols-1 md:grid-cols-2 items-center">
        <div className="order-last md:order-first">
          <div className="flex items-center justify-between border-b-2 border-gray-medium px-4 pb-4">
            <span>Pay With</span>
            <span>{payment_data.paymentMethod}</span>
          </div>
          <CopySegment
            amountInCrypto={payment_data.amount}
            amountInUSD={sellInvoice.amount_due_in_usd}
            crypto={payment_data.paymentMethod}
            walletAddress={sellInvoice.btc_destination}
          />
        </div>
        <div className="md:my-0 my-5">
          {countdown.isComplete ? (
            <InvoiceExpired
              onRestartProcess={onRestartProcess}
              invoiceId={sellInvoice.btc_invoice_id}
              orderId={sellInvoice.order_id}
            />
          ) : (
            <>
              <ScanSegment
                paymentLink={sellInvoice.payment_link}
                amount={payment_data.amount}
                paymentMethod={payment_data.paymentMethod}
                address={sellInvoice.btc_destination}
              />
            </>
          )}
          <div className="text-center">
            <h3 className="text-primary mb-2 font-bold text-xl">
              {' '}
              Awaiting Payment...{' '}
            </h3>
            <p className="">
              {countdown.hours}hours {countdown.minutes}min {countdown.seconds}
              sec remaining.
            </p>{' '}
          </div>
        </div>
      </section>
      <div className="flex flex-col items-center px-10">
        <Button className="mt-8 w-full md:w-auto">
          <a href={sellInvoice.payment_link} target="_blank" rel="noreferrer">
            Open Wallet
          </a>
        </Button>
      </div>
    </>
  );
};
