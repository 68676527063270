import { OrderComplete } from 'features/buy/components/OrderComplete';
import { OrderInformation } from 'features/buy/components/OrderInformation';
import { TritonStatuses } from 'features/buy/types/tritonStatuses';
import { useEffect, useState } from 'react';
import { Transaction } from '../types/transaction';
import { useGetExchangeRate } from 'common/api';
import { GetExchangeRateResponse } from 'common/types';
import { POLLING_INTERVAL_IN_MS } from 'features/buy/util/constants';
import { Loader } from '@mantine/core';

type TritonBuyModalProps = {
  transaction: Transaction;
};

export const TritonBuyModal = ({ transaction }: TritonBuyModalProps) => {
  const [transactionStatus, setTransactionStatus] = useState<TritonStatuses>(
    transaction.status as TritonStatuses
  );

  // Poll to update exchange rate
  const exchangeRateQuery = useGetExchangeRate({
    from: 'USD',
    to: transaction.crypto,
    exchange: parseInt(transaction.amount, 10),
  });
  useEffect(() => {
    const interval = setInterval(
      () => exchangeRateQuery,
      POLLING_INTERVAL_IN_MS
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  switch (transactionStatus) {
    case TritonStatuses.NEW:
      return (
        <OrderInformation
          transaction={transaction}
          onSuccess={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
      );
    case TritonStatuses.COMPLETED:
      return (
        <>
          <div className="">
            {exchangeRateQuery.isLoading ? (
              <div className="flex justify-center">
                <Loader size="md" color="teal" />
              </div>
            ) : (
              <OrderComplete
                exchangeRateInfo={exchangeRateQuery.data!}
                fundingSource={transaction.location.name}
                minerFee={0}
              />
            )}
          </div>
        </>
      );
  }
  return <div></div>;
};
