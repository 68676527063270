import Button from 'components/Button';
import { CashoutItem } from './CashoutItem/CashoutItem';
import { useAuth } from 'features/auth';
import { AddCashoutMethod } from './AddCashoutMethod';
import { useEffect, useState } from 'react';
import { object } from 'yup';
import { useGetAccountInfo } from 'features/account/api/getAccountInfo';

export const CashoutMethods = () => {
  const { user, setUser } = useAuth();
  const [cashoutMethodOpened, setcashoutMethodOpened] = useState(false);

  const { update: fetchUserInfo } = useGetAccountInfo((resp) =>
    setUser(resp.data)
  );

  useEffect(() => fetchUserInfo({}), []);
  return (
    <section>
      <h1 className="text-2xl font-bold">My Cashout Methods</h1>

      {user ? (
        <div className="my-6 mx-a grid max-w-7xl grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-3">
          {Object.entries(user.cashout_methods).map((cashoutMethod) => {
            return <CashoutItem cashoutItem={cashoutMethod} />;
          })}
        </div>
      ) : (
        <div></div>
      )}
    </section>
  );
};
