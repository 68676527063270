import { DropdownMenu } from './DropdownMenu';
import { Input } from '@mantine/core';
import bitcoin from 'assets/icons/bitcoin.svg';
import { SearchIcon, PlusSmIcon, MenuIcon } from '@heroicons/react/solid';
import { BellIcon } from '@heroicons/react/outline';
import { User } from 'common/types';
import { Link } from 'react-router-dom';
import { Popover } from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  getNotifications,
  markAsRead,
  Notifications,
  UnbankNotification,
} from 'features/notifications';
import { POLLING_INTERVAL_IN_MS } from 'features/buy/util/constants';

type HeaderProps = {
  user: User;
  onOpenDrawer: () => void;
  onLogout: () => void;
};

const Header = ({ user, onLogout, onOpenDrawer }: HeaderProps) => {
  const [showNotifications, setShowNotifications] = useState(false);

  const toggleShowNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleCloseNotifications = async () => {
    // Generate list of notification ids

    if (notifications) {
      const notificationIds = notifications?.map((notification) => {
        return notification.id;
      });

      // Mark notificationsf as read
      const result = markAsRead(notificationIds);

      // Refresh notifications list
      await fetchNotifications();
    }

    toggleShowNotifications();
  };
  const [notifications, setNotifications] = useState<UnbankNotification[]>();
  const fetchNotifications = async () => {
    if (user) {
      const fetchedNotifications = await getNotifications(user.email);

      const filteredNotifications = fetchedNotifications.data.filter(
        (notification) => {
          return !notification.read_at;
        }
      );

      setNotifications(filteredNotifications);
    }
  };

  useEffect(() => {
    if (user) {
      // Refresh notifications if there are notifications
      if (notifications) {
        console.log('Polling for notifs');
        const interval = setInterval(
          () => fetchNotifications(),
          POLLING_INTERVAL_IN_MS / 2
        );

        return () => {
          clearInterval(interval);
        };
        // Populate locations on first render
      } else {
        console.log('fetching notifs');
        fetchNotifications();
      }
    }
  }, []);

  return (
    <header className="mb-2 flex items-center justify-between">
      <h2 className=" flex gap-2 text-2xl font-bold">
        <MenuIcon className="h-8 md:hidden" onClick={onOpenDrawer} />
        Hi {user.firstname}
      </h2>
      <div className="flex items-center justify-between space-x-4 md:justify-center">
        {/* <Input
          className="hidden lg:block"
          classNames={{
            filledVariant: 'bg-white/20',
            input: 'text-white placeholder:text-white',
          }}
          icon={<SearchIcon className="h-4 text-white" />}
          variant="filled"
          placeholder="Search"
          radius="xl"
          size="md"
        /> */}

        <Link
          to="/dashboard/buy"
          className="btn hidden border-white bg-white text-primary md:inline-flex"
        >
          <img src={bitcoin} className="h-3" alt="Bitcoin Logo" />
          <span>Buy</span>
        </Link>
        <Link
          to="/dashboard/sell"
          className="btn hidden border border-white bg-none md:inline-flex"
        >
          <PlusSmIcon className="h-5 shrink-0" /> <span>Sell</span>
        </Link>

        <div className="h-full w-[1px] bg-white" />

        <Popover
          className="border-0 focus:outline-0 focus:border-0"
          width={480}
          radius="md"
          spacing="xs"
          opened={showNotifications}
          onClose={handleCloseNotifications}
          position="bottom"
          target={
            <div
              onClick={toggleShowNotifications}
              className="cursor-pointer rounded-full bg-white/20 p-2 hover:bg-white/30"
            >
              {notifications?.length ? (
                <div className="absolute top-0 right-0 h-3 w-3 text-center my-0.5 rounded-full -mx-0.5 bg-red-400 z-2"></div>
              ) : (
                <div> </div>
              )}

              <BellIcon className="h-5 text-white" />
            </div>
          }
        >
          <Notifications notifications={notifications} />
        </Popover>

        <DropdownMenu onLogout={onLogout} user={user} />
      </div>
    </header>
  );
};

export default Header;
