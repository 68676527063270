import { axios } from 'lib/axios';
import { LoginApiResponse } from 'features/auth/types/login';
import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { TwoFactorQRResponse, TwoFactorResponse } from '../types/TwoFactorTypes';


// Toggle 2FA
export const enableTwoFactorAuth = async () => {
    const resp = await axios.post<TwoFactorResponse>('/api/account/two-factor-authentication', {
        type: 'authenticator'
    });
    return resp.data;
}

export const disableTwoFactorAuth = async () => {
    const resp = await axios.delete<TwoFactorResponse>('/api/account/two-factor-authentication', {
        data: {
            type: 'authenticator'
        }

    });
    return resp.data;
}

export const verifyTwoFactor = async (code: string, type: string) => {
    const resp = await axios.post<LoginApiResponse>('/api/account/two-factor-challenge', {
        code: code,
        type: type
    })
    return resp.data;
}

export const confirmTwoFactor = async (code: string, type: string) => {
    const resp = await axios.post<LoginApiResponse>('/api/account/confirmed-two-factor-authentication', {
        code: code,
        type: type
    })
    return resp.data;
}

export const getTwoFactorQR = async () => {
    const resp = await axios.get<TwoFactorQRResponse>('/api/account/two-factor-qr-code', {
    });
    return resp.data;
}


export const getTwoFactorSecretKey = async () => {
    const resp = await axios.get<{ secretKey: string }>('api/account/two-factor-secret-key', {})
    return resp.data
}



export const getRecoveryCodes = async () => {
    const resp = await axios.get<[]>('/api/account/two-factor-recovery-codes', {

    })
    return resp.data
}

export const regenerateRecoveryCodes = async () => {
    const resp = await axios.post<TwoFactorResponse>('/api/account/two-factor-recovery-codes', {})

    return resp.data
}


// Two Factor Auth Hooks
export const useRegenerateRecoveryCodes = (
    onSuccess: OnSuccessCallback<TwoFactorResponse>) => {
    return useUpdateApi(regenerateRecoveryCodes, { onSuccess, successNotificationText: "Recovery Codes Succesfully Regenerated" })
}

export const useEnableTwoFactorAuth = (
    onSuccess: OnSuccessCallback<TwoFactorResponse>
) => {
    return useUpdateApi(enableTwoFactorAuth, { onSuccess });
};


export const useDisableTwoFactorAuth = (
    onSuccess: OnSuccessCallback<TwoFactorResponse>
) => {
    return useUpdateApi(disableTwoFactorAuth, {
        onSuccess,
        successNotificationText: '2-Step Authentication is now disabled'
    });
};
