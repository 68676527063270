import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { Loader } from '@mantine/core';
import { useGetTransaction } from 'features/sell/api/getTransaction';
import { Transaction } from 'features/transactions';
import { useEffect } from 'react';
import { GreendotStatuses } from '../types/greendotStatuses';
const POLLING_INTERVAL_IN_MS = 1000 * 60 * 2;

interface ProcessingDepositProps {
  onPaymentProcessed: (data: Transaction) => void;
  transactionID: string;
}
export const ProcessingDeposit = ({
  onPaymentProcessed,
  transactionID,
}: ProcessingDepositProps) => {
  const getInvoiceQuery = useGetTransaction((data) => {
    if (data.status === GreendotStatuses.CREDITED) {
      onPaymentProcessed(data);
    }
  });
  useEffect(() => {
    const interval = setInterval(
      () => getInvoiceQuery.update(transactionID),
      POLLING_INTERVAL_IN_MS
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      {' '}
      <div className="mx-auto flex max-w-lg flex-col items-center text-center lg:w-2/3 my-5">
        {' '}
        <div className=" mb-4 rounded-full bg-light-green p-2">
          <ExclamationCircleIcon className="h-[100px] text-primary" />
        </div>
        <div className="mx-auto">
          <h3 className="font-bold text-xl"> Your Deposit is processing</h3>

          <p className="my-2">
            Your deposit is processsing.It may take up to 15 minutes for us to
            receive your deposit
          </p>
          <p className="my-5">
            After cash has been added to your account, you will convert your
            balance to crypto currency.
          </p>

          <div className="mt-10 text-gray-secondaryDark flex justify-center gap-2">
            <Loader size="sm" /> Checking for status change...
          </div>
        </div>
      </div>
    </div>
  );
};
