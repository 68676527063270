import { Loader, Select } from '@mantine/core';
import { useSystemInfo } from 'common';
import Button from 'components/Button';
import TextField from 'components/TextField';
import { useGetExchangeRate } from 'common/api';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { currencyFormatter } from 'utils/formatters';
import * as Yup from 'yup';
import { useDebouncedValue } from '@mantine/hooks';
import { GetExchangeRateResponse, SupportedPaymentMethod } from 'common/types';
import { useAuth } from 'features/auth';
import notification from 'utils/notification';
import NumberField from 'components/NumberField';
import { ArrowNarrowLeftIcon } from '@heroicons/react/solid';

import { getCryptoCurrencies } from 'features/wallet/util/walletIcons';
import { CryptoSelectItem } from 'features/wallet/components/CryptoSelectItem';

type SelectCryptoProps = {
  onSelectCrypto: (data: GetExchangeRateResponse) => void;
  paymentMethod: string;
  displayAmount: boolean;
  goBack: () => void;
};

export const SelectCrypto = ({
  onSelectCrypto,
  paymentMethod,
  displayAmount,
  goBack,
}: SelectCryptoProps) => {
  const [crypto, setCrypto] = useState<string | null>();
  const { systemInfo, isLoading: isSysInfoLoading } = useSystemInfo();

  useEffect(() => {
    if (crypto) {
      formik.setFieldValue('crypto', crypto);
    }
  }, [crypto]);

  const { user } = useAuth();

  const { errors, touched, handleSubmit, handleBlur, handleChange, ...formik } =
    useFormik<{
      crypto: string;
      amount: string;
      showAmount: boolean;
      useAccountBalance: boolean;
    }>({
      initialValues: {
        amount: displayAmount ? '' : '0',
        crypto: '',
        showAmount: displayAmount ? true : false,
        useAccountBalance: paymentMethod === SupportedPaymentMethod.UNBANK_CASH,
      },
      validationSchema: Yup.object({
        amount: Yup.number()
          .when('showAmount', {
            is: true,
            then: Yup.number().required('Please enter the amount'),
          })
          .when('useAccountBalance', {
            is: true,
            then: Yup.number()
              .required()
              .max(
                parseFloat(user?.account.balance!),
                'You cannot enter a value greater than your account balance'
              ),
          }),
        crypto: Yup.string().required('Please select your crypto currency'),
      }),
      onSubmit: (values) => {
        onSelectCrypto(exchangeRateQuery.data!);
      },
    });

  const [debouncedAmount] = useDebouncedValue(formik.values.amount, 500);

  const exchangeRateQuery = useGetExchangeRate({
    from: 'USD',
    to: formik.values.crypto,
    exchange: parseInt(debouncedAmount, 10),
  });

  // const isDisabled = () => {
  //   const balance = user?.account.balance;
  //   return (
  //     paymentMethod === SupportedPaymentMethod.UNBANK_CASH &&
  //     parseFloat(balance!) < 20
  //   );
  // };
  const handleUseAccountBalance = () => {
    if (user) {
      const balance = user.account.balance;
      if (parseFloat(balance) > 20) {
        formik.setFieldValue('amount', balance);
      } else {
        notification.notifyError('Your account balance needs to be above $20');
      }
    }
  };

  /** Custom onChange to prevent user from adding digits into phone number field */
  const onChange = (event: ChangeEvent<any>) => {
    const value = (event.target as HTMLInputElement).value;
    const isValid = /^\d+$/.test(value);

    if (isValid) {
      (event.target as HTMLInputElement).value = value;
    } else {
      (event.target as HTMLInputElement).value = value.replace(/[^\d+]/g, '');
    }

    // console.log(event.target.value);
    handleChange(event);
  };

  return (
    <>
      <ArrowNarrowLeftIcon
        className="h-6 my-3 text-tw-gray-600 cursor-pointer"
        onClick={goBack}
      />
      <div className="mx-auto flex  flex-col gap-4 text-center lg:w-1/3 lg:max-w-xs my-4">
        {formik.values.amount && formik.values.crypto && displayAmount && (
          <>
            <div className="text-3xl font-bold text-primary">
              {currencyFormatter(parseInt(formik.values.amount, 10))}
            </div>
            <div className="w-full rounded-md bg-light-green py-4 text-lg font-semibold text-primary">
              {exchangeRateQuery.isLoading ? (
                <div className="flex justify-center">
                  <Loader size="md" color="teal" />
                </div>
              ) : (
                `= ${exchangeRateQuery.data?.exchange_result}${exchangeRateQuery.data?.to}`
              )}
            </div>
          </>
        )}
      </div>
      <h3 className="mt-8 text-center text-2xl font-semibold">
        Choose Cryptocurrency
      </h3>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="grid grid-rows-2 gap-8">
          {isSysInfoLoading ? (
            <Loader size="md" color="teal" />
          ) : (
            <Select
              placeholder="Choose Crypto"
              label="Cryptocurrency"
              itemComponent={CryptoSelectItem}
              styles={{
                input: { fontSize: '18px', height: '55px' },
                label: { fontSize: '14px' },
              }}
              variant="filled"
              color="gray-dark"
              size="lg"
              {...formik.getFieldProps('crypto')}
              value={crypto}
              onChange={setCrypto}
              error={errors.crypto && touched.crypto && errors.crypto}
              data={getCryptoCurrencies()}
            />
          )}
          {displayAmount && (
            <TextField
              placeholder="Enter Amount"
              label="Amount"
              name="amount"
              value={formik.values.amount}
              onChange={onChange}
              onBlur={handleBlur}
              error={errors.amount && touched.amount && errors.amount}
            />
          )}
        </div>

        <div className="mx-auto mt-5 flex  flex-col lg:w-1/3 lg:max-w-xs">
          <div className="flex gap-6 justify-center">
            {paymentMethod === SupportedPaymentMethod.UNBANK_CASH && (
              <Button
                className="border border-primary bg-white text-primary"
                fullWidth={true}
                size="lg"
                onClick={handleUseAccountBalance}
              >
                {' '}
                Use Full Account Balance{' '}
              </Button>
            )}
            <Button
              fullWidth={true}
              disabled={exchangeRateQuery.isLoading}
              type="submit"
              size="lg"
            >
              Proceed
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
