import { createContext, useContext, useEffect, useState } from 'react';
import { IBuyLimits, ISellLimits, TransactionLimit } from '../types/limits';
import { getTransactionLimits } from '../api/getLimits';
import { useAuth } from 'features/auth';

type LimitsContextType = {
  buyLimits: IBuyLimits | undefined;
  sellLimits: ISellLimits | undefined;
  overallLimits: TransactionLimit | undefined;
};

const LimitsContext = createContext<LimitsContextType>({
  sellLimits: undefined,
  buyLimits: undefined,
  overallLimits: undefined,
});

export const LimitsProvider: React.FC = ({ children }) => {
  const [buyLimits, setBuyLimits] = useState<IBuyLimits>();
  const [sellLimits, setSellLimits] = useState<ISellLimits>();
  const [overallLimits, setOverallLimits] = useState<TransactionLimit>();

  const { user } = useAuth();

  useEffect(() => {
    const fetchTransactionLimits = async () => {
      const limits = await getTransactionLimits();
      setBuyLimits(limits.buy);
      setSellLimits(limits.sell);
      setOverallLimits(limits.overall);
    };

    if (user) {
      fetchTransactionLimits().catch(console.error);
    }
  }, []);

  return (
    <LimitsContext.Provider
      value={{
        buyLimits,
        sellLimits,
        overallLimits,
      }}
    >
      {children}
    </LimitsContext.Provider>
  );
};

export function useLimits() {
  const context = useContext(LimitsContext);
  if (!context) {
    throw new Error('useLimits must be used within an LimitsProvider.');
  }
  return context;
}
