import Button from 'components/Button';
import QRCode from 'react-qr-code';
import { useClipboard } from '@mantine/hooks';
import { Tooltip } from '@mantine/core';

type ScanSegmentProps = {
  paymentLink: string;
  amount: string;
  paymentMethod: string;
  address: string;
};

export const ScanSegment = ({
  paymentLink,
  amount,
  paymentMethod,
  address,
}: ScanSegmentProps) => {
  const { copy } = useClipboard();
  return (
    <div className="flex flex-col items-center px-10">
      <QRCode
        value={paymentLink}
        className="mx-auto my-auto lg:place-self-end"
      />
      <p className="my-3 w-2/3 text-center">
        Scan the barcode with your phone to complete payment with your mobile
        wallet of choice.
      </p>
    </div>
  );
};
