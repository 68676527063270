import { OnSuccessCallback, useUpdateApi } from 'common/hooks';
import { axios } from 'lib/axios';

const resendEmailVerificaiton = async () => {
  const resp = await axios.post('/api/account/resend/verification/email');
  return resp.data;
};

export const useResendEmailVerification = () => {
  return useUpdateApi(resendEmailVerificaiton, {
    successNotificationText: 'New verification code sent!',
  });
};
