import { useAuth } from 'features/auth';
import React, { ReactNode } from 'react';
import storage from 'utils/storage';

interface SiftTrackerProps {
  children: ReactNode;
}
const SiftTracker = () => {
  const { setUser, user } = useAuth();
  const _user_id = user?.id;
  const _session_id = storage.getToken();

  const _sift = (window._sift = window._sift || []);
  _sift.push(['_setAccount', process.env.REACT_APP_SIFT_BEACON_KEY]);
  _sift.push(['_setUserId', _user_id]);
  _sift.push(['_setSessionId', _session_id]);
  _sift.push(['_trackPageview']);

  (function () {
    function ls() {
      const e = document.createElement('script');
      e.src = 'https://cdn.sift.com/s.js';
      document.body.appendChild(e);
    }
    if (window.attachEvent) {
      window.attachEvent('onload', ls);
    } else {
      window.addEventListener('load', ls, false);
    }
  })();
  return <div></div>;
};

export default SiftTracker;
