import { KyckStatus } from 'features/sell/types/kyckStatuses';
import { useEffect, useState } from 'react';
import { TransactionsTable } from '..';
import { TransactionFilterConfig } from '../types/TransactionFilterConfig';
import { detectViewport } from 'utils/layout';
import { TransactionList } from '../components/TransactionList/TransactionList';

export const PickupReadyPage = () => {
  const [filters, setFilters] = useState<TransactionFilterConfig>({
    status: KyckStatus.PICKUP_READY,
  });

  const [viewport, setViewport] = useState(detectViewport());

  // Create a function to update the viewport/device
  const updateViewport = () => {
    setViewport(detectViewport());
  };

  // Use the useEffect hook to detect any changes to the user's viewport/device
  useEffect(() => {
    window.addEventListener('resize', updateViewport);
    return () => window.removeEventListener('resize', updateViewport);
  });

  return (
    <div className="dashboard-page">
      <header className="mb-12">
        <h2 className="mb-2 text-secondary">Pickup Ready Transactions</h2>
        <p>Here is a list of your transactions that are ready for pickup.</p>
      </header>
      <TransactionsTable filters={filters} />
      {/* {viewport === 'mobile' ? (
        <TransactionList />
      ) : (
       
      )} */}
    </div>
  );
};
