import {
  SelectWithCards,
  SelectWithCardsOption,
} from 'components/SelectWithCards';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import accountBalanceIcon from 'assets/imgs/unbank-logo.svg';
import cashIcon from 'assets/icons/cashicon.png';
import debitCardBuyIcon from 'assets/icons/DebitCardIcon.png';
import { SupportedPaymentMethod } from 'common/types';
import { useState } from 'react';
import { getPaymentMethod } from 'common';
import { useLimits } from 'features/transactions/providers/limits';

type SelectPaymentMethodProps = {
  onSelectPaymentMethod: (method: string) => void;
};

export const SelectPaymentMethod = ({
  onSelectPaymentMethod,
}: SelectPaymentMethodProps) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const handleClick = () => {
    onSelectPaymentMethod(selectedPaymentMethod);
  };

  const { buyLimits } = useLimits();
  const paymentMethods: SelectWithCardsOption[] = [
    {
      label: getPaymentMethod(SupportedPaymentMethod.BANK_CARD),
      value: SupportedPaymentMethod.BANK_CARD,
      body: `Minimum $${buyLimits?.bank_card.min_per_transaction}, Maximum $${buyLimits?.bank_card.max_per_transaction} per transaction`,
      limit: `${buyLimits?.bank_card.daily_limit}`,
      src: debitCardBuyIcon,
    },
    {
      label: getPaymentMethod(SupportedPaymentMethod.CASH),
      value: SupportedPaymentMethod.CASH,
      body: `Minimum $${buyLimits?.cash.min_per_transaction}, Maximum $${buyLimits?.cash.max_per_transaction} per transaction`,
      limit: `${buyLimits?.cash.daily_limit}`,
      disabled: false,
      src: cashIcon,
    },
    {
      label: getPaymentMethod(SupportedPaymentMethod.UNBANK_CASH),
      value: SupportedPaymentMethod.UNBANK_CASH,
      body: `Purchase cryptocurrency using your account balance`,
      limit: `${buyLimits?.account_balance.daily_limit}`,
      src: accountBalanceIcon,
    },
  ];

  return (
    <div className="mt-10 text-center">
      <h3 className="my-8 text-2xl font-semibold">
        Choose your payment method
      </h3>

      <SelectWithCards
        options={paymentMethods}
        onSelectOption={setSelectedPaymentMethod}
        columns={3}
      />
      <p className="mb-14">
        If you don't have any payment methods, you can go ahead and{' '}
        <Link
          className="text-primary underline-offset-1 hover:underline"
          to="/dashboard/account/payment-methods"
        >
          add a payment method
        </Link>
      </p>
      <Button
        onClick={handleClick}
        disabled={selectedPaymentMethod === undefined}
      >
        Proceed
      </Button>
    </div>
  );
};
