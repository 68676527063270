import { Switch } from '@mantine/core';
import {
  NotificationSetting,
  NotificationTypes,
} from '../types/notificationSettings';

interface NotificationSettingProps {
  settingName: string;
  notificationState: NotificationSetting;
  update: (eventTarget: HTMLInputElement) => void;
}
export const NotificationSwitch = ({
  settingName,
  notificationState,
  update,
}: NotificationSettingProps) => {
  return (
    <Switch
      onLabel="ON"
      offLabel="OFF"
      checked={
        notificationState[settingName as keyof NotificationSetting] as boolean
      }
      name={settingName}
      onChange={(e) => {
        update(e.currentTarget);
      }}
    />
  );
};
