import { useAuth } from 'features/auth';
import { ContinueProcess } from './ContinueProcess';
import { SocialSecurityForm } from './SocialSecurityForm';

export const IdentityVerification = () => {
  const { user, setUser } = useAuth();

  return (
    <div>
      <header>
        <h1 className="text-2xl font-bold">Identity Verification</h1>
        <small>
          We require additional informaiton to veify your identity in order to
          comply with banking and government regulations.
        </small>
      </header>
      {user?.ssn !== '' ? (
        <ContinueProcess />
      ) : (
        <SocialSecurityForm onSuccess={setUser} />
      )}
    </div>
  );
};
