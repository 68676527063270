import Button from 'components/Button';
import TextField from 'components/TextField';
import { Textarea } from '@mantine/core';
import { useRemoveCashoutMethod } from 'features/account/api/removeCashoutMethod';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface DeleteCashoutMethodProps {
  cashoutMethod: string;
}
export const DeleteCashoutMethod = ({
  cashoutMethod,
}: DeleteCashoutMethodProps) => {
  const { handleChange, handleSubmit } = useFormik<{ reason: string }>({
    initialValues: {
      reason: '',
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      // Delete Cashout Method
      update({ cashoutMethod: cashoutMethod, reason: values.reason });
    },
  });

  const { isLoading, update } = useRemoveCashoutMethod((resp) => {});

  return (
    <form className="mt-8" onSubmit={handleSubmit}>
      <p className="text-sm text-gray-darker mb-3">
        (Optional) Please state your reason for removing your cashout method
        below
      </p>
      <Textarea
        name="reason"
        onChange={handleChange}
        minRows={5}
        placeholder="Reason for deleting cashout method"
      />
      <Button size="sm" className="my-3" loading={isLoading}>
        Remove Cashout Method
      </Button>
    </form>
  );
};
