import { ArrowRightIcon } from '@heroicons/react/outline';
import { useGetExchangeRate } from 'common/api';
import { GetExchangeRateResponse } from 'common/types';
import Button from 'components/Button';
import { Transaction } from 'features/transactions';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useConvertCash } from '../../api/buyCrypto';
import * as Yup from 'yup';

interface ConvertCashProps {
  transaction: Transaction;
  onSuccess: (exchangeRateInfo: GetExchangeRateResponse) => void;
}

export const ConvertCash = ({ transaction, onSuccess }: ConvertCashProps) => {
  const { isLoading, update: convertCash } = useConvertCash((resp) => {
    onSuccess(exchangeRateInfo!);
  });

  const [isNotEditing, setIsNotEditing] = useState(true);
  const { errors, touched, ...formik } = useFormik<{ balance: string }>({
    initialValues: {
      balance: '0',
    },
    validationSchema: Yup.object({
      balance: Yup.number().required(),
    }),
    onSubmit: (values) => {
      handleCashConversion(values.balance);
    },
  });
  const [exchangeRateInfo, setExchangeRateInfo] =
    useState<GetExchangeRateResponse>();

  const handleCashConversion = (balance: string) => {
    convertCash({ order_id: transaction.order_id, balance: balance });
  };

  const exchangeRateQuery = useGetExchangeRate({
    from: 'USD',
    to: transaction.crypto,
    exchange: parseInt(transaction.amount, 10),
  });

  // Retrieve exchange rate
  useEffect(() => {
    setExchangeRateInfo(exchangeRateQuery.data!);
  }, []);
  return (
    <div className="text-center flex flex-col items center mt-5">
      <h2>Convert to Bitcoin</h2>
      <p className="font-light">Convert cash to bitcoin after making deposit</p>
      <form onSubmit={formik.handleSubmit}>
        <input
          className="text-6xl font-thin text-center w-1/2"
          id="convert-cash-currency-text"
          placeholder="Cash Amount"
          {...formik.getFieldProps('balance')}
        />
        <p className="text-xs text-gray-secondaryDark my-5">
          Please note that you cannot enter a balance greater than the amount in
          your unbank account
        </p>
        <div className="flex items-center justify-center gap-8 my-5 mb-12">
          <p className="text-primary font-semibold bg-light-green py-2 px-7 rounded">
            Cash
          </p>
          <ArrowRightIcon className="text-primary h-[20px]" />
          <p className="text-primary font-semibold bg-light-green py-2 px-7 rounded">
            Bitcoin
          </p>
        </div>
        <Button loading={isLoading} type="submit">
          {' '}
          Proceed
        </Button>
      </form>
    </div>
  );
};
