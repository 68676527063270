import { Loader } from '@mantine/core';
import Button from 'components/Button';
import { useGetTransaction } from 'features/sell/api/getTransaction';
import { CountDownTimer } from 'features/sell/components/CountDownTimer';
import { Transaction } from 'features/transactions';
import { useEffect, useState } from 'react';
import { BuyCryptoDTO, useBuyCrypto } from '../api/buyCrypto';
import { BuyCryptoReceipt } from '../types/buyCryptoReceipt';
import { TritonStatuses } from '../types/tritonStatuses';
import { POLLING_INTERVAL_IN_MS } from '../util/constants';

interface OrderInformationProps {
  // If transaction is create, send buy request
  // Otherwise pull barcode with transaction id
  buyCryptoRequestBody?: BuyCryptoDTO;
  transaction?: Transaction;
  onSuccess: (transaction: Transaction) => void;
}

export const OrderInformation = ({
  buyCryptoRequestBody,
  onSuccess,
  transaction,
}: OrderInformationProps) => {
  const [tritonData, setTritonData] = useState<BuyCryptoReceipt>();

  useEffect(() => {
    if (buyCryptoRequestBody) {
      buyCrypto(buyCryptoRequestBody);

      console.log(tritonData);
    }
  }, []);

  /**  Polls for a change in transaction status */
  useEffect(() => {
    // Retrieve order ID based on if the transaction is being created or viewed
    if (transaction || tritonData) {
      let orderId = '';
      if (transaction) {
        orderId = transaction.order_id;
      } else if (tritonData) {
        orderId = tritonData.order_id;
      }

      // Poll to check for Transaction Status change based on orderId
      const interval = setInterval(
        () => getInvoiceQuery.update(orderId),
        POLLING_INTERVAL_IN_MS
      );

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  /** Used for polling until barcode is scanned */
  const getInvoiceQuery = useGetTransaction((data) => {
    console.log(data.status);
    if (data.status === TritonStatuses.COMPLETED) {
      // Proceed to Order Complete Screen
      onSuccess(data);
    }
  });
  const { isLoading, update: buyCrypto } = useBuyCrypto((resp) => {
    setTritonData(resp);
  });
  return (
    <div className="mx-auto flex max-w-lg flex-col items-center text-center lg:w-2/3">
      {tritonData && (
        <div className="my-3">
          <h1 className="text-center"> Order Number</h1>
          <div className="my-12 w-full  flex items-center bg-gray-medium py-4 text-xl font-semibold text-gray-darker">
            <p className="tracking-[15px] px-24 text-center">
              {tritonData.order_id}
            </p>
          </div>
          <CountDownTimer
            inLineTimer={true}
            showProgressBar={false}
            startDate={tritonData.transfer_date_utc}
            endDate={tritonData.expiry_date_utc}
          />
        </div>
      )}{' '}
      {transaction && (
        <div className="my-3">
          <h1 className="text-center"> Order Number</h1>
          <div className="my-12 w-full  text-center flex items-center bg-gray-medium py-4 text-xl font-semibold text-gray-darker">
            <p className="tracking-[15px] px-24 text-center">
              {transaction.order_id}
            </p>
          </div>
          <CountDownTimer
            inLineTimer={true}
            showProgressBar={false}
            startDate={transaction.transfer_date_utc}
            endDate={transaction.expiry_date_utc}
          />
        </div>
      )}
      {!transaction && !tritonData && (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};
