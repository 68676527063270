import React, { useEffect, useState } from 'react';
import { getWallets } from '../api/getWalletInfo';
import { Wallet } from '../types/wallet';
import { CryptoWallet } from './CryptoWallet';
import WalletIcon from 'assets/icons/wallet.svg';
import { ModifyWalletModal } from './ModifyWalletModal';
import { Modal } from '@mantine/core';
import { DeleteWalletModal } from './DeleteWalletModal';
import ResponsiveModal from 'components/Modal';

export const WalletSettings = () => {
  const [wallets, setWallets] = useState<Wallet[]>();

  const [noWallets, setNoWallets] = useState(0);
  const [saveModalOpened, setSaveModalOpened] = useState(false);
  useEffect(() => {
    // Pull list of wallets
    const fetchWallets = async () => {
      const walletData = await getWallets();
      setWallets(walletData.data);
      setNoWallets(walletData.data.length);
    };

    fetchWallets().catch(console.error);
  }, []);

  const walletsToRender = () => {
    if (noWallets > 6) {
      return 6;
    } else {
      return 6 - noWallets;
    }
  };
  return (
    <div>
      <h1 className="text-2xl font-bold">Saved Wallets</h1>
      <p>You can add, edit or view the wallets added to your account here.</p>

      <div className="">
        {wallets ? (
          <div className="grid md:grid-cols-3 gap-3 my-4 grid-cols-1">
            {/* Render all wallets in account */}
            {wallets.length > 0 &&
              wallets.map((wallet, index) => <CryptoWallet wallet={wallet} />)}

            {/* Display Add Wallet for remainder of wallets not added*/}
            {[...Array(walletsToRender())].map((item) => (
              <div className="flex justify-center align-center h-full  rounded-md p-8 text-gray-dark shadow-custom">
                <button
                  className="my-auto text-primary flex items-center gap-3"
                  onClick={() => {
                    setSaveModalOpened(true);
                  }}
                >
                  <img src={WalletIcon} alt="Wallet" className="h-8" />
                  <p>Add Crypto Wallet</p>
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <ResponsiveModal
        opened={saveModalOpened}
        className="flex flex-col items-center  "
        size="lg"
        onClose={() => {
          setSaveModalOpened(false);
          window.location.reload();
        }}
      >
        <ModifyWalletModal
          type={'save'}
          onSuccess={(walletData) => {
            setSaveModalOpened(false);
            // Refresh Page
          }}
        />
      </ResponsiveModal>
    </div>
  );
};
