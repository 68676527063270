import {
  getTwoFactorQR,
  getTwoFactorSecretKey,
} from 'features/account/api/twoFactorAuth';
import { TwoFactorQRResponse } from 'features/account/types/TwoFactorTypes';
import { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';

export const TwoFactorQR = () => {
  // State data for 2FA QR
  const [qrCode, setQrCode] = useState<TwoFactorQRResponse>();
  const [secretKey, setSecretKey] = useState<string>('');
  useEffect(() => {
    const fetchQRData = async () => {
      const qrCode = await getTwoFactorQR();
      const secretKey = await getTwoFactorSecretKey();
      setQrCode(qrCode);
      setSecretKey(secretKey.secretKey);
    };

    fetchQRData().catch(console.error);
  }, []);

  return (
    <div>
      <p className="pb-2">
        Scan the image below with the two factor authentication app on your
        mobile device. If you can't do this enter the text below instead
      </p>
      {qrCode?.svg ? (
        <div>
          <div
            className="flex flex-col items-center text-center my-5"
            dangerouslySetInnerHTML={{ __html: qrCode.svg }}
          ></div>

          <div>
            <p className="bg-gray-medium  text-center p-3 text-sm text-gray-secondaryDark mb-5  ">
              {secretKey}
            </p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center text-center my-5">
          <p className="font-bold my-3"> Generating QR Code</p>
          <Loader color="green" size="xl" />;
        </div>
      )}
    </div>
  );
};
