import { Radio, RadioGroup } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import Button from 'components/Button';
import {
  FilterConfig,
  LocationType,
} from 'features/locations/types/filterConfig';
import { useState } from 'react';

const distanceFilters = [
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '75',
    value: '75',
  },
];

const typeFilters = [
  {
    label: 'Buy',
    value: 'buy',
  },
  {
    label: 'Sell',
    value: 'sell',
  },
  {
    label: 'Both',
    value: 'both',
  },
];

type FilterProps = {
  onSelectFilter: (filter: FilterConfig) => void;
  initialFilters: FilterConfig;
  disableTypeFilter: boolean;
  onClose: () => void;
};

export const Filter = ({
  onSelectFilter,
  onClose,
  initialFilters,
  disableTypeFilter,
}: FilterProps) => {
  const [filter, setFilter] = useState<FilterConfig>(initialFilters);

  const ref = useClickOutside(onClose);

  const handleDistance = (e: string) => {
    setFilter((prev) => ({ ...prev, distance: e }));
  };

  const handleType = (e: LocationType) => {
    setFilter((prev) => ({ ...prev, locationType: e }));
  };

  const handleFilter = () => {
    onSelectFilter(filter);
    onClose();
  };

  const handleClear = () => {
    onSelectFilter({
      locationType: !disableTypeFilter ? 'both' : initialFilters.locationType,
      distance: '25',
    });
    onClose();
  };
  return (
    <div
      ref={ref}
      className="absolute top-full right-0 w-72 rounded-lg bg-white p-2 shadow-lg"
    >
      <header className="mb-2 text-lg font-semibold text-secondary">
        Filter
      </header>
      <div className="flex flex-col gap-2">
        <div>
          <RadioGroup
            label="ATM Distance"
            size="sm"
            classNames={{
              radio: 'checked:bg-primary',
            }}
            name="distance"
            value={filter.distance}
            onChange={handleDistance}
          >
            {distanceFilters.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </RadioGroup>
        </div>
        <div>
          <RadioGroup
            size="sm"
            label="Transaction Type"
            classNames={{
              radio: 'checked:bg-primary',
            }}
            name="type"
            value={filter.locationType}
            onChange={handleType}
          >
            {typeFilters.map(({ label, value }) => (
              <Radio key={value} value={value} disabled={disableTypeFilter}>
                {label}
              </Radio>
            ))}
          </RadioGroup>
        </div>
      </div>
      <div className="mt-4 flex gap-2">
        <Button size="sm" fullWidth color="bg-gray-dark" onClick={handleClear}>
          Clear
        </Button>
        <Button size="sm" onClick={handleFilter} fullWidth>
          Filter
        </Button>
      </div>
    </div>
  );
};
