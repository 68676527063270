import { Dialog } from '@headlessui/react';
import { MapIcon } from '@heroicons/react/outline';
import { FilterIcon, SearchIcon } from '@heroicons/react/solid';
import { Loader, Modal } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import Button from 'components/Button';
import TextField from 'components/TextField';
import Transitions from 'components/Transitions';
import { useEffect, useState } from 'react';
import notification from 'utils/notification';
import { useGetLocations } from '../../api/getLocations';
import { AtmLocation } from '../../types';
import { FilterConfig, LocationType } from '../../types/filterConfig';
import { AtmLocationCard } from '../AtmLocationCard';
import { AtmLocationDetails } from '../AtmLocationDetails';
import { Map } from '../Map';
import { Filter } from './Filter';
import { Pagination } from '@mantine/core';

type FindLocationsProps = {
  onSelectLocation?: (location: AtmLocation) => void;
  transactionType: LocationType;
};

const DEBOUNCE_TIME = 500;

export const FindLocations = ({
  onSelectLocation,
  transactionType = 'both',
}: FindLocationsProps) => {
  const [userCoords, setUserCoords] = useState<GeolocationCoordinates>();
  const [selectedLocation, setSelectedLocation] = useState<AtmLocation>();
  const [isMapOpen, setisMapOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<FilterConfig>({
    distance: '25',
    locationType: transactionType,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, DEBOUNCE_TIME);

  const { isLoading, data, hasError } = useGetLocations({
    distance: filters.distance,
    buy_or_sell: filters.locationType,
    term: debouncedSearchTerm,
    long: userCoords?.longitude,
    lat: userCoords?.latitude,
  });

  // Pagination
  const PAGE_SIZE = 9;
  const [page, setPage] = useState(1);
  const [locations, setLocations] = useState(data?.rows.slice(0, PAGE_SIZE));

  // Update Pagination params
  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setLocations(data?.rows.slice(from, to));
  }, [data?.rows, page]);

  const deselectLocation = () => setSelectedLocation(undefined);

  const handleUpdateCenter = (latitude: number, longitude: number) => {
    setUserCoords((prev) => ({ ...prev!, latitude, longitude }));
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserCoords(position.coords);
      },
      (error) => {
        notification.notifyError(error.message);
      }
    );
  }, []);

  const handleViewLocationFromMap = (location: AtmLocation) => {
    setSelectedLocation(location);
    setisMapOpen(false);
  };

  const handleOpenMap = () => {
    setisMapOpen(true);
  };

  return (
    <>
      {selectedLocation ? (
        <AtmLocationDetails
          transactionType={transactionType}
          location={selectedLocation!}
          onGoBack={deselectLocation}
          onClick={() => {
            if (onSelectLocation) {
              onSelectLocation(selectedLocation);
            }
          }}
        />
      ) : (
        <div>
          <div className="mb-4 flex justify-end space-x-4 md:mx-20">
            <div className="relative z-30">
              <Button
                condensed
                onClick={() => setShowFilters((prev) => !prev)}
                iconPosition="left"
                icon={<FilterIcon className="h-4" />}
                className="border border-primary bg-white text-primary"
                size="sm"
              >
                Filter
              </Button>
              <Transitions.AppearDown reveal={showFilters}>
                <Filter
                  disableTypeFilter={transactionType !== 'both'}
                  initialFilters={filters}
                  onSelectFilter={setFilters}
                  onClose={() => setShowFilters(false)}
                />
              </Transitions.AppearDown>
            </div>

            <Button
              condensed
              onClick={handleOpenMap}
              iconPosition="left"
              icon={<MapIcon className="h-4" />}
              size="sm"
            >
              Map
            </Button>
          </div>
          <TextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="md:mx-20"
            icon={<SearchIcon className="h-6" />}
            placeholder="Address, City or Zip"
          />

          <div className=" mt-8">
            {isLoading ? (
              <div className="flex justify-center">
                <Loader size="lg" />
              </div>
            ) : hasError ? (
              <div>Could not fetch locations</div>
            ) : (
              <div>
                <div>
                  <Modal
                    opened={isMapOpen}
                    size="lg"
                    onClose={() => setisMapOpen(false)}
                  >
                    <div className="mx-auto grid max-w-screen gap-8 lg:grid-cols-1 h-[420px]">
                      {data && (
                        <Map
                          onClose={() => setisMapOpen(false)}
                          onUpdateCenter={handleUpdateCenter}
                          locations={data?.rows}
                          onViewLocation={handleViewLocationFromMap}
                        />
                      )}
                    </div>
                  </Modal>
                </div>
                {locations && (
                  <div className="mx-auto grid max-w-screen-2xl gap-8 lg:grid-cols-2 2xl:grid-cols-3">
                    {locations.map((location) => (
                      <AtmLocationCard
                        key={location.atm_id}
                        onViewLocation={setSelectedLocation}
                        location={location}
                      />
                    ))}
                    <Pagination
                      page={page}
                      onChange={setPage}
                      total={10}
                      classNames={{
                        active: 'bg-primary text-white',
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
