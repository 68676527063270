import { useEffect, useState } from 'react';
import { useRoutes } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { routes } from './routes';
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
} from 'firebase/messaging';
import axios from 'axios';
import NotFound from 'components/NotFound';
import UnsupportedLocation from 'components/UnsupportedLocation';

function App() {
  const firebaseMessagingInit = async () => {
    if (!('Notification' in window)) {
      console.log("Browser doesn't support Notification");
    } else if (Notification.permission === 'granted') {
      // next steps for notifications
    } else if (Notification.permission !== 'denied') {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          // next steps for notifications.
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    firebaseMessagingInit();
  }, []);

  // const [location, setLocation] = useState<GeolocationData>();

  // const getLocationData = async () => {
  //   const res = await axios.get(`https://geolocation-db.com/json/`);
  //   setLocation(res.data);
  // };

  // useEffect(() => {
  //   getLocationData();
  // }, []);

  const routeElement = useRoutes(routes);

  return <>{routeElement}</>;
}

export default App;
