import { Route, Routes } from 'react-router-dom';
import { SellLimits } from './SellLimits';
import { TransactionLimitsPage } from './TransactionLimitsPage';
import { TransactionsPage } from './TransactionsPage';
import { Transactions } from './Transactions';

export const TransactionRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Transactions />} />
      <Route path="limits" element={<TransactionLimitsPage />} />
      <Route path="/limits/sell" element={<div></div>} />
    </Routes>
  );
};
