import { NavLink } from 'react-router-dom';

export type NavItemProps = {
  name: string;
  link: string;
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
};

const NavItem = ({ name, link, icon }: NavItemProps) => {
  const Icon = icon;
  return (
    <NavLink to={link}>
      {({ isActive }) => (
        <div
          className={`group relative flex items-center gap-4 fill-gray-dark px-8 py-3 text-sm ${
            isActive
              ? 'bg-light-green text-primary'
              : 'text-gray-dark hover:bg-light-green hover:text-primary'
          }`}
        >
          <span
            className={`absolute left-0 h-full w-1 rounded-r-full bg-primary ${
              isActive ? 'block' : 'hidden group-hover:block'
            }`}
          />
          <Icon className="h-6 shrink-0" /> <span>{name}</span>
        </div>
      )}
    </NavLink>
  );
};

export default NavItem;
