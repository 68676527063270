import {
  confirmTwoFactor,
  getTwoFactorQR,
  TwoFactorQRResponse,
  verifyTwoFactor,
} from 'features/account';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import TextField from 'components/TextField';
import logo from 'assets/imgs/unbank-logo.svg';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import storage from 'utils/storage';
import { LoginApiResponse } from 'features/auth/types/login';
import notification from 'utils/notification';

interface ConfirmTwoFactorProps {
  handleTwoFactorConfirmed: () => void;
}
export const ConfirmTwoFactor = ({
  handleTwoFactorConfirmed,
}: ConfirmTwoFactorProps) => {
  // API returns QR Response or Empty array
  const [twoFactorResult, setTwoFactorResult] = useState<LoginApiResponse>();

  const navigate = useNavigate();
  const { handleChange, handleSubmit } = useFormik<{ code: string }>({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      console.log('submitting');
      const result = await confirmTwoFactor(values.code, 'authenticator');
      setTwoFactorResult(result);

      if (result.user) {
        handleTwoFactorConfirmed();
      } else {
        notification.notifyError(
          'Please ensure that the verification code is correct'
        );
      }
    },
  });
  return (
    <div className="">
      <main className=" ">
        <p className="mb-4 text-sm text-gray-secondaryDark ">
          {' '}
          For your security, please confirm your two factor code from your
          authenticator app to continue.
        </p>
        <section className="">
          <form onSubmit={handleSubmit}>
            <TextField
              name="code"
              onChange={handleChange}
              className="w-full py-2"
              placeholder="Verification Code"
            ></TextField>
            <div>
              <Button type="submit" size="sm">
                Confirm
              </Button>{' '}
            </div>
          </form>
        </section>
      </main>
    </div>
  );
};
