import { axios } from 'lib/axios';
import { OnSuccessCallback, useFetchApi, useUpdateApi } from 'common/hooks';
import { FingerprintLogResult, SecureChallengeResponse } from '../types/threeDSResponse';
import { BuyCryptoDTO } from './buyCrypto';
// import { THREEDS_API_SDK, THREEDS_API_KEY, THREEDS_API_URL } from 'config';
// import scriptjs from 'scriptjs';

export type ThreeDSDTO = {
  amount: number;
  card_ref?: string;
  challenge?: number;
};

export type ThreeDSChallengeDTO = {
  trans_id: string;
}
/**
 *
 */

export const performSecureChallenge = async (credentials: BuyCryptoDTO) => {
  // Retrieve user data for cardref
  const cardRef = await getUserCardRef();

  // Extract params for threeds
  const threeDSParams: ThreeDSDTO = {
    amount: credentials.amount,
    challenge: 1,
    card_ref: cardRef
  };

  const resp = await axios.post<SecureChallengeResponse>(
    '/api/service/3ds/token',
    {
      ...threeDSParams,
    }
  );

  return resp.data;
};


export const getUserCardRef = async () => {
  // Extract params for threeds
  const resp = await axios.get<any>(
    '/api/account/data',
    {

    }
  );

  return resp.data.data.defaultBankCardRef;
};

export const logFingerprintResult = async () => {

  // Retrieve user data for cardref
  const cardRef = await getUserCardRef();

  // Make fingerprint request
  const resp = await axios.get<FingerprintLogResult>(`/api/account/bank/card/${cardRef}/log?properties[status]=Y`);
  {

  }

  return resp.data;

}


export const threeDsCall = async () => {

};


export const usePerformSecureChallenge = (
  onSuccess: OnSuccessCallback<SecureChallengeResponse>
) => {
  return useUpdateApi(performSecureChallenge, {
    onSuccess,
  });
};
