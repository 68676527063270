import { NotificationCategory } from 'features/account/types/notificationSettings';
import { getPlaintextCategory } from 'features/notifications/util';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface NotificationCardProps {
  notificationCategories: NotificationCategory[];
  name: string;
  setVisible: () => void;
}

export const NotificationCard = ({
  notificationCategories,
  name,
  setVisible,
}: NotificationCardProps) => {
  const navigate = useNavigate();
  return (
    <article
      className="px-3 py-4 hover:bg-tw-gray-100 cursor-pointer rounded flex justify-between items-center"
      onClick={() => {
        setVisible();
      }}
    >
      <div className="">
        <h3 className="font-bold text-md"> {name} </h3>
        <p>
          {notificationCategories.map((category: string, index) => (
            <span className="text-sm text-gray-secondaryDark">
              {getPlaintextCategory(category)}
              {index !== notificationCategories.length - 1 ? ', ' : ''}
            </span>
          ))}
        </p>
      </div>
      <ChevronRight className="h-12 text-tw-gray-600 " />
    </article>
  );
};
