import { Routes, Route } from 'react-router-dom';
import LoginPage from './LoginPage';
import { TwoFactorVerify } from './TwoFactorVerify';
import RegistrationPage from './RegistrationPage';

export const AuthRoutes = () => (
  <Routes>
    <Route path="register" element={<RegistrationPage />} />
    <Route path="login" element={<LoginPage />} />
    <Route path="2fa-verify" element={<TwoFactorVerify />} />
  </Routes>
);
