import IdentityVerificationRequired from 'components/IdentityVerificationRequired';
import UnsupportedLocation from 'components/UnsupportedLocation';
import { useNavigate } from 'react-router-dom';
import { useVerify } from '../providers/verification';
import { EmailVerificationForm } from './EmailVerificationForm';

type VerificationRouteProps = {
  component: JSX.Element;
};

/** Route to restrict access for the various flows that require verification
 *  This currently applies for Geofencing and the account level flows
 */
const VerificationRoute = ({ component }: VerificationRouteProps) => {
  const { isUserLocationValid, isEmailVerified, isLevelOneVerified, user } =
    useVerify();

  const navigate = useNavigate();
  console.log(isLevelOneVerified);
  if (!isUserLocationValid) {
    return (
      <div>
        <UnsupportedLocation />
      </div>
    );
  } else if (!isEmailVerified) {
    return (
      <EmailVerificationForm
        onComplete={() => {
          navigate('/dashboard/overview');
        }}
        email={user?.email!}
      />
    );
  } else if (!isLevelOneVerified) {
    return <IdentityVerificationRequired />;
  } else {
    return component;
  }
};

export default VerificationRoute;
