import Aside from './Aside';
import Header from './Header';

import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth, useLogout } from 'features/auth';
import { useEffect, useState } from 'react';
import Transitions from 'components/Transitions';
import { useSystemInfo } from 'common';
import { useVerify } from 'features/auth/providers/verification';

export const Dashboard = () => {
  const { user, clearUser } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { update: handleLogout } = useLogout(() => {
    navigate('/auth/login');
    clearUser();
  });

  const { fetchSystemInfo } = useSystemInfo();
  const { getLocationData } = useVerify();

  useEffect(() => {
    fetchSystemInfo();
    getLocationData();
  }, []);

  return (
    <div className="flex min-h-screen">
      <Aside isDrawerOpen={isDrawerOpen} />
      <div className="grow bg-gray-light">
        <div className="h-[210px] bg-primary py-6 px-8 pb-20 text-white">
          <Header
            user={user!}
            onOpenDrawer={() => setIsDrawerOpen(true)}
            onLogout={() => handleLogout({})}
          />
        </div>
        {/* Backdrop */}
        <Transitions.FadeIn reveal={isDrawerOpen}>
          <div
            onClick={() => setIsDrawerOpen(false)}
            className="fixed top-0 left-0 z-40 h-screen w-screen bg-[rgba(0,0,0,0.3)]"
          />
        </Transitions.FadeIn>
        <main className="relative -top-20 mx-2 md:mx-8">
          <Outlet />
        </main>
      </div>
    </div>
  );
};
