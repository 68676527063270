import { Invoice } from 'features/sell';
import { getPaymentType } from 'features/transactions';
import {
  capitalizeFirstLetter,
  dateFormatter,
  numberFormatter,
} from 'utils/formatters';

type InvoiceRowProps = {
  invoice: Invoice;
  onSelectInvoice: (invoice: Invoice) => void;
};

const columnStyle = 'border-x-2 border-gray-light px-3 py-4 text-center';

export const InvoiceRow = ({ invoice, onSelectInvoice }: InvoiceRowProps) => {
  return (
    <tr
      onClick={() => onSelectInvoice(invoice)}
      key={invoice.btc_invoice_id}
      className="cursor-pointer border-b-2 border-gray-light hover:bg-tw-gray-100"
    >
      <td className={`${columnStyle} border-l-0`}>{invoice.order_id}</td>
      <td className={columnStyle}>
        <span
          className={`${
            invoice.transaction_type === 'buy'
              ? 'bg-light-green text-primary'
              : 'bg-light-blue text-secondary'
          } py-1.5 px-8  font-semibold`}
        >
          {invoice.transaction_type}
        </span>
      </td>
      <td className={columnStyle}>
        {invoice.amount} {invoice.currency}
      </td>
      <td className={columnStyle}>
        {numberFormatter(parseFloat(invoice.amount_in_crypto), 8)}
      </td>
      <td className={columnStyle}>{invoice.crypto}</td>
      <td className={columnStyle}>
        {dateFormatter(new Date(invoice.transfer_date))}
      </td>
      <td className={columnStyle}>
        {capitalizeFirstLetter(invoice.service_method)}
      </td>
      <td className={columnStyle}>{invoice.status}</td>
      <td className={`${columnStyle} border-r-0`}>
        {invoice.expiry_date !== ''
          ? dateFormatter(new Date(invoice.expiry_date))
          : 'N/A'}
      </td>
    </tr>
  );
};
