import { DuplicateIcon } from '@heroicons/react/outline';
import { useClipboard } from '@mantine/hooks';
import { CountDownTimer } from './CountDownTimer';
import exclamation from 'assets/icons/exclamation.svg';
import ncrPayLogo from 'assets/imgs/NCRpay360.png';
import touchIcon from 'assets/icons/touch-icon.png';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { KyckStatus } from '../types/kyckStatuses';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { useGetTransaction } from '../api/getTransaction';
import { Transaction } from 'features/transactions';

type CashCodeProps = {
  accountNumber: string;
  transaction: Transaction;
};

const CHECK_INVOICE_INTERVAL_IN_MS = 1000 * 60 * 2;

export const CashCode = ({ accountNumber, transaction }: CashCodeProps) => {
  const [status, setStatus] = useState(transaction.status);
  const { copy } = useClipboard();
  const navigate = useNavigate();

  const getInvoiceQuery = useGetTransaction((data) => {
    setStatus(data.status);
  });

  useEffect(() => {
    const interval = setInterval(
      () => getInvoiceQuery.update(transaction.invoice!.btc_invoice_id),
      CHECK_INVOICE_INTERVAL_IN_MS
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (status === KyckStatus.EXPIRED) {
    return (
      <div className="flex flex-col items-center justify-center text-center">
        <ExclamationCircleIcon className="h-8" />
        <h3>Invoice Expired</h3>
      </div>
    );
  }

  if (status === KyckStatus.SUCCESS) {
    return (
      <div className="flex flex-col items-center justify-center text-center">
        <CheckCircleIcon className="h-8 text-primary" />
        <h3>Transaction Completed!</h3>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center space-y-12 text-center">
      <div>
        <img src={ncrPayLogo} className="mx-auto h-10" alt="NCRpay360 logo" />
        <h3 className="text-2xl font-semibold">Cash Code</h3>
        <small>Use the code below to pickup your cash.</small>
        <div className="relative my-4">
          <div className="bg-gray-medium px-28 py-2 text-center text-xl font-semibold tracking-wider ">
            {transaction.data.payStub.responseData.strAuthorizationCode}
          </div>
          <button
            onClick={() =>
              copy(transaction.data.payStub.responseData.strAuthorizationCode)
            }
            className="absolute right-0 top-0 rounded-md border-2 border-gray-medium p-2 hover:bg-gray-medium"
          >
            <DuplicateIcon className="h-6 text-gray-dark" />
          </button>
        </div>
        <div>
          <span className="font-semibold text-primary">Account Number: </span>
          {accountNumber}
        </div>
      </div>

      <div className="w-full lg:w-4/5">
        <CountDownTimer
          showProgressBar={true}
          startDate={transaction.transfer_date_utc}
          endDate={transaction.expiry_date_utc}
        />
      </div>
      <div className="rounded bg-light-green p-4">
        <img src={exclamation} className="mx-auto mb-4 h-8" alt="" />
        <div className="flex items-center ">
          <ol className="ml-4 list-outside list-decimal space-y-4 text-left">
            <li>
              Select "NCRpay360 Cash Code" on the ATM to initiate your
              transaction.
            </li>
            <li>Input your phone number into the account number section.</li>
            <li>Enter your NCRpay360 Cash Code.</li>
          </ol>
          <img src={touchIcon} className="h-12" alt="" />
        </div>
      </div>
      <Button
        className="w-full md:w-auto"
        onClick={() => navigate('/dashboard/transactions')}
      >
        View Transactions
      </Button>
    </div>
  );
};
