import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-spring-bottom-sheet/dist/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProviders } from 'providers/app';
import SiftTracker from 'features/sift/components/SiftTracker';

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <SiftTracker />
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
