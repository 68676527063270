import { Navigate, RouteObject } from 'react-router';
import { Dashboard } from 'features/misc';
import { GuardedRoute, AuthRoutes, VerificationRoute } from 'features/auth';
import NotFound from 'components/NotFound';
import { OverviewPage } from 'features/overview';
import { AccountRoutes } from 'features/account';
import { PickupReadyPage, TransactionsPage } from 'features/transactions';
import { SellPage } from 'features/sell';
import { FindLocationsPage } from 'features/locations';
import { PublicLocationsPage } from 'features/locations/';
import { BuyPage } from 'features/buy';
import ComingSoon from 'components/ComingSoon';
import { SupportPage } from 'features/support';
import { TransactionRoutes } from 'features/transactions/routes';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/dashboard/overview" />,
  },
  {
    path: '/view-locations',
    element: <PublicLocationsPage />,
  },
  {
    path: '/pickupready',
    element: <Navigate to="/dashboard/pickupready" />,
  },
  {
    path: 'auth/*',
    element: <AuthRoutes />,
  },
  {
    path: 'dashboard/*',
    element: <GuardedRoute component={<Dashboard />} />,
    children: [
      {
        path: 'account/*',
        element: <AccountRoutes />,
      },
      {
        path: 'locations',
        element: <FindLocationsPage />,
      },
      {
        path: 'overview',
        element: <OverviewPage />,
      },
      {
        path: 'pickupready',
        element: <PickupReadyPage />,
      },
      {
        path: 'sell',
        element: <VerificationRoute component={<SellPage />} />,
      },
      {
        path: 'buy',
        element: <VerificationRoute component={<BuyPage />} />,
      },
      {
        path: 'transactions/*',
        element: <TransactionRoutes />,
      },
      {
        path: 'help',
        element: <SupportPage />,
      },
      {
        path: '*',
        element: (
          <div className="overflow-hidden rounded-md">
            <NotFound coloredBackground={false} />
          </div>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
