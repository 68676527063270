import { useEffect, useState, useRef } from 'react';
import { BuyCryptoDTO, useBuyCrypto } from '../api/buyCrypto';
import { BuyCryptoReceipt } from '../types/buyCryptoReceipt';
import { Barcode } from 'components/Barcode';
import { CountDownTimer } from 'features/sell/components/CountDownTimer';
import { Transaction } from 'features/transactions';
import Button from 'components/Button';
import { useGetTransaction } from 'features/sell/api/getTransaction';
import { GreendotStatuses } from '../types/greendotStatuses';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { useReactToPrint } from 'react-to-print';
import { GreendotReceipt } from './GreendotReciept/GreendotReciept';
import { useAuth } from 'features/auth';
import { useGetAccountInfo } from 'features/account';
import { Loader } from '@mantine/core';
import { SocialSecurityForm } from 'features/account/components/IdentityVerification/SocialSecurityForm';
import { User } from 'common/types';

interface AddCashProps {
  // If transaction is create, send buy request
  // Otherwise pull barcode with transaction id
  transactionType: 'create' | 'view';
  transaction?: Transaction;
  buyCryptoRequestBody?: BuyCryptoDTO;
  onDeposit: (data: Transaction) => void;
  showBarcode?: boolean;
}

interface VerificationText {
  header: string;
  body: string;
  loaderText: string;
  icon?: JSX.Element;
}
export const AddCash = ({
  transactionType,
  transaction,
  buyCryptoRequestBody,
  showBarcode,
  onDeposit,
}: AddCashProps) => {
  const { isLoading, update: buyCrypto } = useBuyCrypto((resp) => {
    setGreendotData(resp);
  });

  // Poll for status update every minute
  const POLLING_INTERVAL_IN_MS = 1000 * 60 * 1;

  // Poll Account details every 20 seconds
  let ACCOUNT_POLLING_INTERVAL_IN_MS = 1000 * 30 * 1;

  const [greendotData, setGreendotData] = useState<BuyCryptoReceipt>();

  /** Checks if the user is level 3 and has their SSN verified */
  const [userVerified, setUserVerified] = useState(false);
  const [ssnVerified, setSSNVerified] = useState(false);
  const [verificationText, setVerificationText] = useState<VerificationText>({
    header: 'Checking User Identiy',
    body: "Please give us a moment to check your account's verification status",
    loaderText: 'Validating SSN and ID',
  });
  const { user, setUser } = useAuth();

  const { update: fetchUserInfo } = useGetAccountInfo((resp) => {
    setUser(resp.data);
    if (user) {
      if (user.is_ssn_verified) {
        setSSNVerified(true);
      }

      if (user.account_level >= 2) {
        setUserVerified(true);
      }
    }
  });

  /** Polls for a change in the user's verification fields
   *  before initiating Greendot transaction */
  useEffect(() => {
    fetchUserInfo({});

    if (!ssnVerified && !userVerified) {
      // Update Verification Text based on user verification
      setVerificationText({
        header: 'Identity Verification Required',
        body: 'Please ensure that you are using a valid ID and SSN for Unbank',
        loaderText: 'Waiting for ID and SSN to be verified',
        icon: <ExclamationCircleIcon className=" h-[80px]" />,
      });

      // Poll user info until identity is verified
      const interval = setInterval(
        () => fetchUserInfo({}),
        POLLING_INTERVAL_IN_MS
      );

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  /** Used for polling until barcode is scanned */
  const getInvoiceQuery = useGetTransaction((data) => {
    console.log(data.status);
    if (data.status === GreendotStatuses.PENDING) {
      // Proceed to convert cash screen after deposit
      onDeposit(data);
    }
  });

  /**  Polls for a change in transaction status */
  useEffect(() => {
    // Retrieve order ID based on if the transaction is being created or viewed
    if (transaction || greendotData) {
      let orderId = '';
      if (transaction) {
        orderId = transaction.order_id;
      } else if (greendotData) {
        orderId = greendotData.id.toString();
      }

      // Poll to check for Transaction Status change based on orderId
      const interval = setInterval(
        () => getInvoiceQuery.update(orderId),
        POLLING_INTERVAL_IN_MS
      );

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  /** Generates the greendot barcode if the user is creating
   * a greendot transaction
   *
   * This barcode is only generated if the user is
   * level 3 and has their SSN/TIN verified
   * */
  useEffect(() => {
    if (transactionType === 'create' && buyCryptoRequestBody) {
      buyCrypto(buyCryptoRequestBody);

      console.log(greendotData);
    }
  }, []);

  // Retrieve greendot location
  const greendotLocation = transaction
    ? transaction.gd_retailer_name
    : greendotData?.data.gd_retailer_name;

  // Handle printing Greendot Barcode
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const greendotSteps = {
    customer: [
      {
        content: (
          <p>
            Take the barcode to{' '}
            <span className="font-semibold text-primary">
              {greendotLocation}
            </span>
            , and have the cashier scan it{' '}
          </p>
        ),
      },
      {
        content: (
          <p>
            Give the cashier the amount of funds you would like to deposit into
            your account
          </p>
        ),
      },
      {
        content: (
          <p>Check the unbank application to see your funds reflected</p>
        ),
      },
    ],
    cashier: [
      {
        content: <p>Select RELOAD on POS & Scan barcode</p>,
      },
      {
        content: <p>Ask customer for amount they'd like to add</p>,
      },
      {
        content: <p>Collect amount (in cash) PLUS retail service fee.</p>,
      },
      {
        content: <p>Complete sale and give customer reciept</p>,
      },
    ],
  };

  // Display Greendot Barcode Screen if user is verified

  // Prompt user to verify otherwise
  if (userVerified && ssnVerified) {
    return (
      <div className="px-3">
        {/* Reciept to be shown */}
        <GreendotReceipt
          steps={greendotSteps}
          transaction={transaction}
          greendotData={greendotData}
          showBarcode={showBarcode}
          ref={printRef}
        />

        <Button
          fullWidth={true}
          className="mb-2 rounded font-semibold"
          onClick={() => {
            handlePrint();
          }}
        >
          {' '}
          Print Barcode{' '}
        </Button>
      </div>
    );
  } else if (userVerified) {
    return (
      <div className="my-4 flex flex-col items-center">
        <div className="text-primary">{verificationText.icon}</div>
        <h1 className="font-bold text-2xl text-center">
          {' '}
          Please Verify your SSN
        </h1>
        <p className="text-center"> Enter your SSN below to validate it</p>
        <SocialSecurityForm onSuccess={() => setSSNVerified(true)} />
      </div>
    );
  } else {
    return (
      <div className="my-4 flex flex-col items-center">
        <div className="text-primary">{verificationText.icon}</div>
        <h1 className="font-bold text-2xl text-center">
          {verificationText.header}
        </h1>
        <p className="text-center">{verificationText.body}</p>
        <Loader size="xl" className="my-5" />
        <p className="text-primary font-sm"> {verificationText.loaderText}</p>
      </div>
    );
  }
};
