import { AddCash } from 'features/buy/components/AddCash';
import { useState, useEffect } from 'react';
import { ProcessingDeposit } from 'features/buy/components/ProcessingDeposit';
import { GreendotStatuses } from 'features/buy/types/greendotStatuses';
import { Transaction } from '../types/transaction';
import { useGetTransaction } from 'features/sell/api/getTransaction';
import { ConvertCash } from 'features/buy/components/ConvertCash/ConvertCash';
import { OrderComplete } from 'features/buy/components/OrderComplete';
import { useGetExchangeRate } from 'common/api';
import { GetExchangeRateResponse } from 'common/types';
import { Loader } from '@mantine/core';
import { TransactionExpired } from 'features/buy/components/TransactionExpired';
import { useNavigate } from 'react-router-dom';

type GreendotBuyModalProps = {
  transaction: Transaction;
};

export const GreendotBuyModal = ({ transaction }: GreendotBuyModalProps) => {
  const [transactionStatus, setTransactionStatus] = useState<GreendotStatuses>(
    transaction.status as GreendotStatuses
  );

  const [exchangeRateInfo, setExchangeRateInfo] =
    useState<GetExchangeRateResponse>();

  /** Used for polling until barcode is scanned */

  // Poll for status update every minute
  const POLLING_INTERVAL_IN_MS = 1000 * 60 * 1;
  const getInvoiceQuery = useGetTransaction((data) => {
    console.log(data.status);
    setTransactionStatus(data.status as GreendotStatuses);
  });

  useEffect(() => {
    // Poll to check for Transaction Status change based on orderId
    const interval = setInterval(
      () => getInvoiceQuery.update(transaction.order_id),
      POLLING_INTERVAL_IN_MS
    );

    return () => {
      clearInterval(interval);
    };
  });

  // Poll to update exchange rate
  const exchangeRateQuery = useGetExchangeRate({
    from: 'USD',
    to: transaction.crypto,
    exchange: parseInt(transaction.amount, 10),
  });
  useEffect(() => {
    const interval = setInterval(
      () => exchangeRateQuery,
      POLLING_INTERVAL_IN_MS
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleUpdateTransaction = (transaction: Transaction) => {
    setTransactionStatus(transaction.status as GreendotStatuses);
  };

  const navigate = useNavigate();
  switch (transactionStatus) {
    case GreendotStatuses.NEW:
      return (
        <AddCash
          onDeposit={handleUpdateTransaction}
          transactionType="view"
          transaction={transaction}
        />
      );
    case GreendotStatuses.PENDING:
      return (
        <ProcessingDeposit
          onPaymentProcessed={handleUpdateTransaction}
          transactionID={transaction.order_id}
        />
      );

    case GreendotStatuses.CREDITED:
      return (
        <ConvertCash
          transaction={transaction}
          onSuccess={(exchangeRateInfo) => {
            setTransactionStatus(GreendotStatuses.COMPLETED);
            setExchangeRateInfo(exchangeRateInfo);
          }}
        />
      );

    case GreendotStatuses.COMPLETED:
      return (
        <>
          <div className="">
            {exchangeRateQuery.isLoading ? (
              <div className="flex justify-center">
                <Loader size="md" color="teal" />
              </div>
            ) : (
              <OrderComplete
                exchangeRateInfo={exchangeRateQuery.data!}
                fundingSource={transaction.location.name}
                minerFee={0}
              />
            )}
          </div>
        </>
      );

    case GreendotStatuses.EXPIRED:
      return (
        <TransactionExpired
          orderId={transaction.order_id}
          onRestartProcess={() => navigate('/dashboard/buy')}
        />
      );
  }

  return <div></div>;
};
