import { Loader, Select } from '@mantine/core';
import { useSystemInfo } from 'common';
import Button from 'components/Button';
import TextField from 'components/TextField';
import { useGetExchangeRate } from 'common/api';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { currencyFormatter } from 'utils/formatters';
import * as Yup from 'yup';
import { CreateInvoiceDTO, useCreateInvoice } from '../api/createInvoice';
import { Invoice } from '../types/invoice';
import { useDebouncedValue } from '@mantine/hooks';
import { CryptoSelectItem } from 'features/wallet/components/CryptoSelectItem';
import { getCryptoCurrencies } from 'features/wallet/util/walletIcons';

type SelectCryptoProps = {
  onSelectCrypto: (sellInvoice: Invoice) => void;
  entity_id?: string;
  cashoutMethod: string;
};

export const SelectCrypto = ({
  onSelectCrypto,
  entity_id,
  cashoutMethod,
}: SelectCryptoProps) => {
  const [crypto, setCrypto] = useState<string | null>();
  const { systemInfo, isLoading: isSysInfoLoading } = useSystemInfo();

  const createInvoiceQuery = useCreateInvoice((data) => {
    onSelectCrypto(data);
  });

  useEffect(() => {
    if (crypto) {
      formik.values.crypto = crypto;
    }
  }, [crypto]);

  const {
    errors,
    touched,

    handleSubmit,
    handleBlur,
    handleChange,
    ...formik
  } = useFormik<Pick<CreateInvoiceDTO, 'amount' | 'crypto'>>({
    initialValues: {
      amount: '',
      crypto: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required()
        .test('multiple-of-20', 'Must be a multiple of 20', (value) =>
          value ? value % 20 === 0 : false
        ),
      crypto: Yup.string().required(),
    }),
    onSubmit: (values) => {
      createInvoiceQuery.update({ cashout_method: cashoutMethod, ...values });
    },
  });

  const [debouncedAmount] = useDebouncedValue(formik.values.amount, 500);

  const { isLoading, data } = useGetExchangeRate({
    from: 'USD',
    to: formik.values.crypto,
    exchange: parseInt(debouncedAmount, 10),
  });

  /** Custom onChange to prevent user from adding digits into phone number field */
  const onChange = (event: ChangeEvent<any>) => {
    const value = (event.target as HTMLInputElement).value;
    const isValid = /^\d+$/.test(value);

    if (isValid) {
      (event.target as HTMLInputElement).value = value;
    } else {
      (event.target as HTMLInputElement).value = value.replace(/[^\d+]/g, '');
    }

    console.log(event.target.value);

    handleChange(event);
  };

  return (
    <>
      <div className="mx-auto mt-12 flex  flex-col gap-8 text-center lg:w-1/3 lg:max-w-xs">
        {formik.values.amount && formik.values.crypto && (
          <>
            <div className="text-3xl font-bold text-primary">
              {currencyFormatter(parseInt(formik.values.amount, 10))}
            </div>
            <div className="w-full rounded-md bg-light-green py-4 text-lg font-semibold text-primary">
              {isLoading ? (
                <div className="flex justify-center">
                  <Loader size="md" color="teal" />
                </div>
              ) : (
                `= ${data?.exchange_result}${data?.to}`
              )}
            </div>
          </>
        )}
      </div>
      <h3 className="my-8 text-center text-2xl font-semibold">
        Choose Cryptocurrency
      </h3>

      <form onSubmit={handleSubmit} className="">
        <div className="grid grid-rows-2 gap-8">
          {isSysInfoLoading ? (
            <Loader size="md" color="teal" />
          ) : (
            <Select
              placeholder="Choose Crypto"
              label="Cryptocurrency"
              itemComponent={CryptoSelectItem}
              styles={{
                input: { fontSize: '18px', height: '55px' },
                label: { fontSize: '14px' },
              }}
              variant="filled"
              color="gray-dark"
              size="lg"
              {...formik.getFieldProps('crypto')}
              value={crypto}
              onChange={setCrypto}
              error={errors.crypto && touched.crypto && errors.crypto}
              data={getCryptoCurrencies()}
            />
          )}
          <TextField
            placeholder="Enter Amount"
            label="Amount"
            type="text"
            name="amount"
            value={formik.values.amount}
            onChange={onChange}
            onBlur={handleBlur}
            error={errors.amount && touched.amount && errors.amount}
          />
        </div>
        <div className="mx-auto mt-5 flex  flex-col lg:w-1/3 lg:max-w-xs">
          <Button
            fullWidth={true}
            type="submit"
            size="lg"
            loading={createInvoiceQuery.isLoading}
          >
            Proceed
          </Button>
        </div>
      </form>
    </>
  );
};
