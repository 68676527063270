import { GetExchangeRateResponse, ServiceProvider } from 'common/types';
import Button from 'components/Button';
import checkCircle from 'assets/icons/check_circle.svg';
import { getPaymentType } from 'features/transactions';
import { currencyFormatter, numberFormatter } from 'utils/formatters';
import { useNavigate } from 'react-router-dom';
import { Transaction } from '../types/transaction';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

type WebReceiptModal = {
  transaction: Transaction;
};

export const WebRecieptModal = ({ transaction }: WebReceiptModal) => {
  const getFundingSource = (
    serviceProvider: string,
    transaction: Transaction
  ) => {
    switch (serviceProvider) {
      case ServiceProvider.MAGIC_PAY:
        return `${transaction.data.card_type} ending in ${transaction.data.last_4}`;

      case ServiceProvider.UNBANK:
        return 'Account Balance';

      case ServiceProvider.GREEN_DOT:
        return 'In Person';

      case ServiceProvider.KYCK:
        return 'In Person';
    }
  };

  // Handle printing Greendot Barcode
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <div>
      <main ref={printRef}>
        <div className="my-16 text-center">
          <img src={checkCircle} className="mx-auto h-[80px] w-auto" alt="" />
          <h3 className="mt-4 text-2xl font-semibold">Completed</h3>
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Order Id</span>
            <span>{transaction.order_id}</span>
          </div>
          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Funding Source</span>
            <span>
              {getFundingSource(transaction.service_provider, transaction)}
            </span>
          </div>
          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Purchased</span>
            <span>
              {numberFormatter(transaction.amount_in_crypto, 8)}{' '}
              {transaction.crypto}
            </span>
          </div>
          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Exchange Rate</span>
            <span>{currencyFormatter(transaction.receipt?.rate!)}</span>
          </div>
          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Miner Fee</span>
            <span>
              {currencyFormatter(transaction.receipt?.miner_fee_per_byte!)}
            </span>
          </div>
          <div className="flex items-center justify-between rounded-md bg-gray-medium py-4 px-8">
            <span>Unbank Fee</span>
            <span>{currencyFormatter(transaction.receipt?.unbank_fee!)}</span>
          </div>
          <div className="flex items-center justify-between rounded-md bg-primary py-4 px-8 font-bold text-white">
            <span>Total Cost</span>
            <span>{currencyFormatter(parseFloat(transaction.amount))}</span>
          </div>
          {console.log(transaction)}
        </div>
      </main>
      <div className="text-center my-4">
        <Button className="mx-3" onClick={handlePrint}>
          {' '}
          Print Reciept
        </Button>
        <a
          href={`https://blockstream.info/tx/${transaction.receipt?.transactionHash}`}
          target="blockchain"
        >
          <Button className="border border-primary bg-white text-primary">
            Open In Blockchain
          </Button>
        </a>
      </div>
    </div>
  );
};
